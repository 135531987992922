import React from "react";
import { Text, Editor, Element, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { styled } from "stitches.config";

import { ReactComponent as BoldIcon } from "./icons/bold.svg";
import { ReactComponent as ItalicIcon } from "./icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "./icons/underline.svg";
import { ReactComponent as LeftAlignIcon } from "./icons/align-left.svg";
import { ReactComponent as CenterAlignIcon } from "./icons/align-center.svg";
import { ReactComponent as RightAlignIcon } from "./icons/align-right.svg";
import { ReactComponent as ListUlIcon } from "./icons/list-ul.svg";
import { ReactComponent as ListOlIcon } from "./icons/list-ol.svg";
import { ReactComponent as TextSizeIcon } from "./icons/text-size.svg";
import { ReactComponent as DropdownIcon } from "./icons/dropdown-icon.svg";
import { ReactComponent as CubeIcon } from "./icons/cube.svg";
import { ReactComponent as ImageIcon } from "./icons/image.svg";
import { ReactComponent as ButtonIcon } from "./icons/button.svg";
import { ReactComponent as DividerIcon } from "./icons/divider.svg";
import { ReactComponent as LinkIcon } from "./icons/link.svg";

import {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenu,
} from "uikit/dropdown";

import { YemEditor } from "./interface";
import { FontSize, Mark } from "./types";
import { lists } from "./lists";
import { Lists } from "@prezly/slate-lists";
import ImageModal from "./imageModal";
import ButtonModal from "./buttonModal";
import LinkModal from "./linkModal";
import { useEditorContext } from "./context";

const Wrapper = styled("div", {
  backgroundColor: "#F3F4F5",
  height: "2.5rem",
  alignItems: "center",
  borderRadius: "$small $small 0 0",
  padding: "0rem 1.25rem",
  display: "flex",
  userSelect: "none",
});

const LeftActions = styled("div", {
  flex: 1,
  display: "flex",
  alignItems: "center",
});

const ElementsButtonWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: "$bold",
  cursor: "pointer",

  "svg.cube": {
    marginRight: "0.25rem",
  },
});

const RightActions = styled("div", {});

const Section = styled("div", {
  borderLeft: "solid 1px #DAE0E7",
  height: "100%",
  display: "flex",
  alignItems: "center",

  "&:first-child": {
    borderLeft: "none",
  },
});

const DropdownWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  marginRight: 6,
  cursor: "pointer",
  borderRadius: 4,
  padding: 4,

  "&:hover": {
    backgroundColor: "$light-neutral-t10",
  },
});

const MarkButtonWrapper = styled("button", {
  display: "inherit",
  border: "none",
  backgroundColor: "transparent",
  padding: 0,
  margin: "0px 2px",
  cursor: "pointer",
  borderRadius: 4,
  transitionDuration: "200ms",

  "&:hover": {
    backgroundColor: "$light-neutral-t10",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$light-neutral",

        "&:hover": {
          backgroundColor: "$light-neutral",
        },
      },
    },
  },
});

const MarkButton: React.FC<{ active?: boolean; mark: Mark }> = ({
  children,
  active,
  mark,
}) => {
  const editor = useSlate();
  return (
    <MarkButtonWrapper
      active={YemEditor.hasMark(editor, mark)}
      onMouseDown={(e) => {
        e.preventDefault();
        YemEditor.toggleMark(editor, mark);
      }}
    >
      {children}
    </MarkButtonWrapper>
  );
};

const BlockButtonWrapper = styled("button", {
  border: "none",
  backgroundColor: "transparent",
  padding: 0,
  display: "flex",
  margin: "0px 2px",
  cursor: "pointer",
  borderRadius: 4,
  transitionDuration: "200ms",

  "&:hover": {
    backgroundColor: "$light-neutral-t10",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$light-neutral",

        "&:hover": {
          backgroundColor: "$light-neutral",
        },
      },
    },
  },
});

const AlignButton: React.FC<{
  align: "left" | "center" | "right";
}> = ({ children, align }) => {
  const editor = useSlate();

  const isBlockActive = () => {
    if (!editor.selection) {
      return false;
    }

    const [match] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n.type !== "image" &&
        n.type !== "button" &&
        n.align === align,
      universal: true,
    });

    return !!match;
  };

  isBlockActive();

  return (
    <BlockButtonWrapper
      active={isBlockActive()}
      onMouseDown={(e) => {
        e.preventDefault();
        Transforms.setNodes(editor, {
          align,
        });
      }}
    >
      {children}
    </BlockButtonWrapper>
  );
};

const toggleListBlock = (
  editor: Editor,
  lists: ReturnType<typeof Lists>,
  type: "unordered-list" | "ordered-list"
): void => {
  if (type === "ordered-list" || type === "unordered-list") {
    lists.wrapInList(editor, type);
    lists.setListType(editor, type);
  } else {
    lists.unwrapList(editor);
    Transforms.setNodes(editor, { type });
  }
};

const ListButtonWrapper = styled("button", {
  padding: 0,
  border: "none",
  backgroundColor: "transparent",
  margin: "0px 2px",
  cursor: "pointer",
  display: "flex",
  borderRadius: 4,
  transitionDuration: "200ms",

  "&:hover": {
    backgroundColor: "$light-neutral-t10",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$light-neutral",

        "&:hover": {
          backgroundColor: "$light-neutral",
        },
      },
    },
  },
});

const ListButton: React.FC<{ listType: "ordered-list" | "unordered-list" }> = ({
  children,
  listType,
}) => {
  const editor = useSlate();
  const listEntries = lists
    .getListsInRange(editor, editor.selection)
    .filter(([node]) => node.type === listType);

  return (
    <ListButtonWrapper
      active={listEntries.length > 0}
      onMouseDown={(e) => {
        e.preventDefault();

        toggleListBlock(editor, lists, listType);
      }}
    >
      {children}
    </ListButtonWrapper>
  );
};

const LinkButtonWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "2rem",
  height: "2rem",
  margin: "0px 2px",
  borderRadius: "$tiny",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "$light-neutral-t10",
  },

  variants: {
    isActive: {
      true: {
        backgroundColor: "$light-neutral",

        "&:hover": {
          backgroundColor: "$light-neutral",
        },
      },
    },
  },
});

const FontSizeButton = () => {
  const editor = useSlate();

  const nodes = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n),
    })
  );
  const fontSizes = nodes.map((n: any) => n[0].fontSize || "normal");

  const fontSize = fontSizes.reduce((acc, curr) => {
    if (!acc) {
      return curr;
    }

    if (acc !== curr) {
      return "mixed";
    }

    return acc;
  }, null);

  const handleChange = React.useCallback(
    (fontSize: FontSize) => {
      Transforms.setNodes(
        editor,
        {
          fontSize,
        },
        {
          match: (n) => !Editor.isEditor(n) && Element.isElement(n),
        }
      );
    },
    [editor]
  );

  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (!open) {
          ReactEditor.focus(editor);
        }
      }}
    >
      <DropdownMenuTrigger>
        <DropdownWrapper>
          <TextSizeIcon />
          <DropdownIcon />
        </DropdownWrapper>
      </DropdownMenuTrigger>

      <DropdownMenuContent onCloseAutoFocus={(e) => e.preventDefault()}>
        <DropdownMenuItem
          css={{ fontSize: "12px", lineHeight: "24px" }}
          onClick={() => handleChange("small")}
          active={fontSize === "small"}
        >
          Small
        </DropdownMenuItem>
        <DropdownMenuItem
          css={{ fontSize: "16px", lineHeight: "24px" }}
          onClick={() => handleChange("normal")}
          active={fontSize === "normal"}
        >
          Normal
        </DropdownMenuItem>
        <DropdownMenuItem
          css={{ fontSize: "20px", lineHeight: "24px" }}
          onClick={() => handleChange("large")}
          active={fontSize === "large"}
        >
          Large
        </DropdownMenuItem>
        <DropdownMenuItem
          css={{ fontSize: "32px", lineHeight: "32px" }}
          onClick={() => handleChange("huge")}
          active={fontSize === "huge"}
        >
          Huge
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const Toolbar = () => {
  const editorContext = useEditorContext();
  const editor = useSlate();
  const [imageModalOpen, setImageModalOpen] = React.useState<number | null>(
    null
  );
  const [buttonModalLastPath, setButtonModalLastPath] = React.useState<
    number | null
  >(null);
  const [linkModalOpen, setLinkModalOpen] = React.useState(false);

  const handleLinkButtonClicked = () => {
    const result = Array.from(
      Editor.nodes(editor, {
        match: (n) => Text.isText(n) && Boolean(n.href),
      })
    );

    if (result.length > 0) {
      for (const resultNode of result) {
        Transforms.setNodes(editor, { href: undefined }, { at: resultNode[1] });
      }
    } else {
      setLinkModalOpen(true);
    }
  };

  const isLinkActive =
    Array.from(
      Editor.nodes(editor, {
        match: (n) => Text.isText(n) && Boolean(n.href),
      })
    ).length > 0;

  const addDivider = React.useCallback(() => {
    const [path] = editor.selection?.anchor.path || [-1];
    Transforms.insertNodes(
      editor,
      [
        {
          type: "divider",
          children: [{ text: "" }],
          isVoid: true,
        },

        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ] as any,
      {
        at: [path + 1],
      }
    );

    Transforms.select(editor, [path + 2]);
  }, [editor]);

  return (
    <React.Fragment>
      <ImageModal
        open={imageModalOpen !== null}
        onOpenChange={() => setImageModalOpen(null)}
        selectedPath={imageModalOpen!}
      />
      <ButtonModal
        open={
          buttonModalLastPath !== null || editorContext.buttonToEdit !== null
        }
        buttonToEdit={editorContext.buttonToEdit || undefined}
        onOpenChange={() => {
          setButtonModalLastPath(null);

          if (editorContext.buttonToEdit) {
            editorContext.setButtonToEdit(null);
          }
        }}
        selectedPath={buttonModalLastPath!}
      />
      <LinkModal
        open={linkModalOpen}
        onOpenChange={() => setLinkModalOpen(false)}
      />
      <Wrapper>
        <LeftActions>
          <Section>
            <FontSizeButton />
          </Section>
          <Section>
            <MarkButton mark="bold">
              <BoldIcon />
            </MarkButton>
            <MarkButton mark="italic">
              <ItalicIcon />
            </MarkButton>
            <MarkButton mark="underline">
              <UnderlineIcon />
            </MarkButton>
          </Section>
          <Section>
            <AlignButton align="left">
              <LeftAlignIcon />
            </AlignButton>
            <AlignButton align="center">
              <CenterAlignIcon />
            </AlignButton>
            <AlignButton align="right">
              <RightAlignIcon />
            </AlignButton>
          </Section>
          <Section>
            <ListButton listType="unordered-list">
              <ListUlIcon />
            </ListButton>
            <ListButton listType="ordered-list">
              <ListOlIcon />
            </ListButton>
          </Section>

          <Section>
            <LinkButtonWrapper
              onClick={handleLinkButtonClicked}
              isActive={isLinkActive}
            >
              <LinkIcon />
            </LinkButtonWrapper>
          </Section>
        </LeftActions>

        <RightActions>
          <DropdownMenu
            onOpenChange={(open) => {
              if (!open) {
                ReactEditor.focus(editor);
              }
            }}
          >
            <DropdownMenuTrigger onMouseDown={(e) => e.preventDefault()}>
              <ElementsButtonWrapper onMouseDown={(e) => e.preventDefault()}>
                <CubeIcon className="cube" />
                Elements
              </ElementsButtonWrapper>
            </DropdownMenuTrigger>

            <DropdownMenuContent
              align="end"
              sideOffset={8}
              onCloseAutoFocus={(e) => e.preventDefault()}
            >
              <DropdownMenuItem
                bold
                onClick={() => {
                  const [selectionPath] = editor.selection?.anchor.path || [-1];
                  setImageModalOpen(selectionPath);
                }}
              >
                <ImageIcon />
                Image
              </DropdownMenuItem>
              <DropdownMenuItem
                bold
                onClick={() => {
                  const [selectionPath] = editor.selection?.anchor.path || [-1];
                  setButtonModalLastPath(selectionPath);
                }}
              >
                <ButtonIcon />
                Button
              </DropdownMenuItem>
              <DropdownMenuItem onClick={addDivider} bold>
                <DividerIcon />
                Divider
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </RightActions>
      </Wrapper>
    </React.Fragment>
  );
};

export default Toolbar;
