import { styled } from "stitches.config";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import {
  GradientDefs,
  AreaSeries,
  LineSeries,
  FlexibleXYPlot,
} from "react-vis";
import Text from "./text";
import Badge from "./badge";
import React from "react";
import { IMetric } from "lib/types";

const Root = styled("div", {
  display: "grid",
  flex: 1,
  border: "solid 1px $late-night-t80",
  borderRadius: "$small",
  padding: "1.5rem",

  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto auto auto",
});

const Label = styled("div", {
  display: "flex",
  alignItems: "center",

  gridRow: 1,
  gridColumn: 1,
  justifySelf: "left",
});

const BadgeWrapper = styled("div", {
  justifySelf: "end",
});

const Value = styled("div", {
  fontSize: "$heading4",
  fontWeight: "$extraBold",
  marginTop: "0.5rem",
});

const Chart = styled("div", {
  height: "2rem",
  gridRow: 3,
  gridColumn: "1 / 3",
});

const Metric: React.FC<{
  metric: IMetric;
  label: string;
  format?: (value: number) => string;
}> = ({ metric, label, format }) => {
  const yDomain = metric.series.reduce(
    (acc, curr) => {
      if (curr.y > acc[1]) {
        return [acc[0], curr.y];
      }

      return acc;
    },
    [0, 0]
  );

  if (yDomain[1] === 0) {
    yDomain[1] = 1;
  }

  const badgeColor: "danger" | "success" = React.useMemo(() => {
    if (metric.value < metric.value_previous_period) {
      return "danger";
    }

    return "success";
  }, [metric.value, metric.value_previous_period]);

  const percentChange = React.useMemo(() => {
    const rate = metric.value / metric.value_previous_period;

    if (isNaN(rate)) {
      return 0;
    } else if (rate === Infinity) {
      return 100;
    } else if (rate >= 0) {
      return Math.round((rate - 1) * 100);
    } else {
      return Math.round((1 - rate) * 100);
    }
  }, [metric.value, metric.value_previous_period]);

  return (
    <Root>
      <Label>
        <Text size="normal" css={{ marginRight: "0.25rem" }}>
          {label}
        </Text>
        <InfoIcon />
      </Label>

      <BadgeWrapper>
        <Badge
          icon={badgeColor === "danger" ? ArrowDown : ArrowUp}
          color={badgeColor}
        >
          {percentChange}%
        </Badge>
      </BadgeWrapper>

      <Value>{format ? format(metric.value) : metric.value}</Value>

      <Chart>
        <FlexibleXYPlot margin={0}>
          <GradientDefs>
            <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="#A7B7CD" stopOpacity={1} />
              <stop offset="100%" stopColor="#A7B7CD" stopOpacity={0} />
            </linearGradient>
          </GradientDefs>
          <AreaSeries
            data={metric.series}
            stroke="transparent"
            fill={"url(#CoolGradient)"}
            yDomain={yDomain}
          />

          <LineSeries
            data={metric.series}
            stroke="#A7B7CD"
            style={{
              fill: "transparent",
              strokeWidth: 2,
              strokeLinecap: "round",
            }}
            yDomain={yDomain}
          />
        </FlexibleXYPlot>
      </Chart>
    </Root>
  );
};

Metric.toString = Root.toString;

export default Metric;
