import { styled } from "stitches.config";

const Text = styled("div", {
  lineHeight: 1.4,
  fontSize: "$normal",

  variants: {
    secondary: {
      true: {
        color: "$midnight-t20",
      },
    },
    size: {
      heading6: {
        fontSize: "$heading6",
      },
      heading5: {
        fontSize: "$heading5",
      },
      medium: {
        fontSize: "$medium",
      },
      normal: {
        fontSize: "$normal",
      },
      small: {
        fontSize: "$smal",
      },
      tiny: {
        fontSize: "$tiny",
      },
    },
    align: {
      center: {
        textAlign: "center",
      },
    },
    weight: {
      medium: {
        fontWeight: "$medium",
      },
      bold: {
        fontWeight: "$bold",
      },
      extraBold: {
        fontWeight: "$extraBold",
      },
    },
  },
});

export default Text;
