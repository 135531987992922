import React from "react";
import axios, { AxiosInstance } from "axios";
import { useAuth } from "./auth";

interface IAPIClientContext {
  client: AxiosInstance;
}

const APIClientContext = React.createContext<IAPIClientContext | null>(null);

export const APIClientProvider: React.FC = ({ children }) => {
  const auth = useAuth();
  const axiosClient = React.useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
      headers: auth.token ? { Authorization: `Bearer ${auth.token}` } : {},
    });
  }, [auth.token]);

  const val = React.useMemo(() => {
    return {
      client: axiosClient,
    };
  }, [axiosClient]);

  return (
    <APIClientContext.Provider value={val}>
      {children}
    </APIClientContext.Provider>
  );
};

export const useAPIClient = () => {
  return React.useContext(APIClientContext)!.client;
};
