import React from "react";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import animationData from "assets/lotties/done-lottie.json";
import Lottie from "react-lottie";
import Text from "uikit/text";

const Wrapper = styled("div", {
  padding: "2rem",
  maxWidth: "1280px",
  minHeight: "100vh",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
});

const YemLogoWrapper = styled("div", {
  svg: {
    top: "2rem",
    position: "fixed",
  },
});

const ContentWrapper = styled("div", {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "30rem",
  margin: "0 auto",
  flex: 1,
});

const OnboardingCompleted = () => {
  return (
    <Wrapper>
      <YemLogoWrapper>
        <YemLogo />
      </YemLogoWrapper>

      <ContentWrapper>
        <Lottie
          height={160}
          width={160}
          options={{ animationData, loop: false }}
        />

        <Text
          size="heading5"
          weight="bold"
          css={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          Setup completed!
        </Text>

        <Text>
          Next our team of experts will carefully prepare a set of emails and
          send to you for approval, we will only start sending emails after
          they've been approved by you.
        </Text>
      </ContentWrapper>
    </Wrapper>
  );
};

export default OnboardingCompleted;
