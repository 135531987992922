import React from "react";
import { styled } from "stitches.config";
import { ToggleGroupItem, ToggleGroupRoot } from "uikit/toggleGroup";
import Metric from "uikit/metric";
import { Table, TableRoot, TableTitle, TBody, THead, TRow } from "uikit/table";
import { useAPIClient } from "lib/api";
import { ILifecycleCampaignPerformance, IMetric } from "lib/types";
import { useQuery } from "react-query";
import { formatCents, formatPercent, intComma } from "lib/utils";
import {
  lifecycleCampaignsGoal,
  lifecycleCampaignsNames,
} from "pages/customize/lifecycleCampaignsHelp";

const Wrapper = styled("div", {
  maxWidth: "62.5rem",
  margin: "0 auto",
  width: "100%",
  padding: "2rem 0rem",
});

const Boxes = styled("div", {
  display: "flex",
  marginTop: "1.5rem",

  [Metric.toString()]: {
    margin: "0rem 0.75rem",

    "&:first-child": {
      marginLeft: "0rem",
    },
    "&:last-child": {
      marginRight: "0rem",
    },
  },
});

const StatsPage = () => {
  const apiClient = useAPIClient();
  const [period, setPeriod] = React.useState("30d");

  const fetchLifecycleCampaignsPerformance = React.useCallback(async () => {
    const days = period === "all_time" ? "" : parseInt(period.replace("d", ""));
    const response = await apiClient.get<{
      revenue_lift: IMetric;
      new_subs: IMetric;
      saved_subs: IMetric;
      lifecycle_campaigns: ILifecycleCampaignPerformance[];
    }>(`/lifecycle-campaigns-performance?days=${days}`);
    return response.data;
  }, [apiClient, period]);

  const lifecycleCampaignsPerformanceQuery = useQuery(
    ["lifecycleCampaignsPerformance", period],
    fetchLifecycleCampaignsPerformance
  );

  return (
    <Wrapper>
      <ToggleGroupRoot type="single" value={period} onValueChange={setPeriod}>
        <ToggleGroupItem value="7d">7D</ToggleGroupItem>
        <ToggleGroupItem value="30d">30D</ToggleGroupItem>
        <ToggleGroupItem value="365d">1Y</ToggleGroupItem>
        <ToggleGroupItem value="all_time">All time</ToggleGroupItem>
      </ToggleGroupRoot>

      {!lifecycleCampaignsPerformanceQuery.isLoading && (
        <>
          <Boxes>
            <Metric
              label="Revenue lift"
              metric={lifecycleCampaignsPerformanceQuery.data!.revenue_lift}
              format={formatCents}
            />
            <Metric
              label="New subs"
              metric={lifecycleCampaignsPerformanceQuery.data!.new_subs}
            />
            <Metric
              label="Saved subs"
              metric={lifecycleCampaignsPerformanceQuery.data!.saved_subs}
            />
          </Boxes>

          <TableRoot css={{ marginTop: "1.5rem" }}>
            <TableTitle>Performance by email</TableTitle>
            <Table>
              <THead>
                <TRow>
                  <th>Email</th>
                  <th>Goal</th>
                  <th>Delivered</th>
                  <th>Open rate</th>
                  <th>Click-through rate</th>
                  <th>Impact</th>
                </TRow>
              </THead>

              <TBody>
                {lifecycleCampaignsPerformanceQuery.data!.lifecycle_campaigns.map(
                  (campaign: ILifecycleCampaignPerformance) => (
                    <TRow key={campaign.name}>
                      <td>
                        {lifecycleCampaignsNames[campaign.name] ||
                          campaign.name}
                      </td>
                      <td>{lifecycleCampaignsGoal[campaign.name]}</td>
                      <td>{intComma(campaign.delivery_count)}</td>
                      <td>
                        {formatPercent(
                          (campaign.open_count / campaign.delivery_count) * 100
                        )}
                      </td>
                      <td>
                        {formatPercent(
                          (campaign.click_count / campaign.open_count) * 100
                        )}
                      </td>
                      <td>
                        {campaign.name === "top_posts"
                          ? "-"
                          : formatCents(campaign.revenue_lift)}
                      </td>
                    </TRow>
                  )
                )}
              </TBody>
            </Table>
          </TableRoot>
        </>
      )}
    </Wrapper>
  );
};

export default StatsPage;
