import React from "react";
import { Dialog, Title } from "uikit/dialog";
import Text from "uikit/text";
import { styled } from "stitches.config";
import Button from "uikit/button";
import { Indicator, ProgressBar } from "uikit/progress";
import { useDropzone } from "react-dropzone";
import { useAPIClient } from "lib/api";
import { useSlate } from "slate-react";
import { Transforms } from "slate";

const UploadBox = styled("div", {
  border: "dashed 2px $late-night-t60",
  cursor: "pointer",
});

const ProgressBox = styled("div", {
  border: "solid 2px transparent",
});

const ContentBox = styled("div", {
  display: "flex",
  height: "19.5rem",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  flexDirection: "column",
});

const ImageModal: React.FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedPath: number;
}> = ({ open, onOpenChange, selectedPath }) => {
  const apiClient = useAPIClient();
  const editor = useSlate();

  const [uploadProgress, setUploadProgress] = React.useState<null | number>(
    null
  );

  React.useEffect(() => {
    setUploadProgress(null);
  }, [open]);

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setUploadProgress(0);
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);
      const response = await apiClient.post<{ image: string }>(
        "/image-upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );

      Transforms.insertNodes(
        editor,
        [
          {
            type: "image",
            children: [{ text: "" }],
            isVoid: true,
            src: response.data.image,
          },

          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ] as any,
        {
          at: [selectedPath + 1],
        }
      );

      Transforms.select(editor, [selectedPath + 2]);
      onOpenChange(false);
    },
    [apiClient, editor, onOpenChange, selectedPath]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Title css={{ marginBottom: "1.5rem" }}>Insert image</Title>

      {uploadProgress === null && (
        <UploadBox
          {...getRootProps()}
          css={{ borderColor: isDragActive ? "$cyan-s10" : "$late-night-t60" }}
        >
          <ContentBox>
            <Text
              weight="bold"
              size="medium"
              css={{
                marginBottom: "0.5rem",
                color: isDragActive ? "$cyan-s10" : "$midnight",
              }}
            >
              Drag images here
            </Text>

            <Text size="small" secondary css={{ marginBottom: "1.5rem" }}>
              or if you prefer...
            </Text>

            <input {...getInputProps()} />

            <Button size="medium">Choose image to upload</Button>
          </ContentBox>
        </UploadBox>
      )}

      {uploadProgress !== null && (
        <ProgressBox>
          <ContentBox>
            <Text weight="bold" size="medium" css={{ marginBottom: "1.5rem" }}>
              Uploading...
            </Text>

            <ProgressBar value={uploadProgress} css={{ maxWidth: "25rem" }}>
              <Indicator style={{ width: `${uploadProgress}%` }} />
            </ProgressBar>
          </ContentBox>
        </ProgressBox>
      )}
    </Dialog>
  );
};

export default ImageModal;
