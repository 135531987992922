import { styled } from "stitches.config";

export const Table = styled("table", {
  width: "100%",
  borderSpacing: 0,
  borderCollapse: "collapse",

  "td,th": {
    textAlign: "left",
  },
});

export const TableRoot = styled("div", {
  borderRadius: "$small",
  border: "1px solid $late-night-t80",
});

export const TableTitle = styled("div", {
  fontSize: "$medium",
  lineHeight: "28px",
  fontWeight: "$medium",
  padding: "1.25rem 1.5rem",
  width: "100%",
});

export const TRow = styled("tr", {});

export const THead = styled("thead", {
  [TRow.toString()]: {
    backgroundColor: "$light-neutral-t40",
    borderTop: "solid 1px $late-night-t80",
    borderBottom: "solid 1px $late-night-t80",
  },

  th: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "$medium",
    color: "$midnight-t50",
    padding: "0.75rem 1.5rem",
  },
});

export const TBody = styled("tbody", {
  td: {
    padding: "1.5rem",
    fontSize: "14px",
    lineHeight: "20px",
  },

  tr: {
    borderBottom: "solid 1px $late-night-t80",

    "&:last-child": {
      borderBottom: "none",
    },
  },
});
