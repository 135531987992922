import { useLocation } from "react-router-dom";

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const numberInTwoDigits = (number: number) => {
  return ("0" + number).slice(-2);
};

export const formatCents = (number: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(number / 100.0);
};

export const intComma = (number: number) => {
  return new Intl.NumberFormat("en-US").format(number);
};

export const formatPercent = (percent: number) => {
  if (isNaN(percent)) {
    return "-";
  }

  return `${Math.round(percent)}%`;
};

export const getInitials = (firstName: string, lastName: string) => {
  return ((firstName[0] || "") + (lastName[0] || "")).toUpperCase();
};
