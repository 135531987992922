import React from "react";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import Text from "uikit/text";
import BoxedImage from "uikit/boxedImage";
import substackGrantAdminAccessImage from "assets/images/substack-grant-admin-access.png";
import Button from "uikit/button";
import { Redirect } from "react-router-dom";
import { useOnboarding } from "./context";
import Alert from "uikit/alert";

const Wrapper = styled("div", {
  padding: "2rem",
  maxWidth: "1280px",
  margin: "0 auto",
});

const YemLogoWrapper = styled("div", {
  height: "3.5rem",
  marginBottom: "2rem",
  svg: {
    position: "fixed",
  },
});

const ContentWrapper = styled("div", {
  width: "32rem",
  margin: "0 auto",
  textAlign: "center",
});

const SubstackAdminAccessGrant = () => {
  const onboarding = useOnboarding();
  const [failed, setFailed] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const checkAdminStatus = React.useCallback(async () => {
    const result = await onboarding.statusQuery?.refetch();

    if (result?.data?.substack.status === "admin") {
      setFinished(true);
    } else {
      setFailed(true);
    }
  }, [onboarding.statusQuery]);

  if (finished || onboarding.statusQuery?.data?.substack.status === "admin") {
    return <Redirect to="/onboarding/connect-stripe-account" />;
  }

  return (
    <Wrapper>
      <YemLogoWrapper>
        <YemLogo />
      </YemLogoWrapper>

      <ContentWrapper>
        {failed && (
          <Alert css={{ marginBottom: "1.5rem" }}>
            <Text size="heading6" weight="bold" css={{ marginTop: "1rem" }}>
              Looks like Yem App isn't an admin yet
            </Text>

            <Text secondary css={{ marginTop: "0.5rem" }}>
              Please verify the instructions below and try again.
            </Text>
          </Alert>
        )}
        <Text size="heading5" weight="bold">
          Grant admin access to Yem App
        </Text>

        <Text secondary css={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}>
          This lets us keep your subscriber data up to date.
        </Text>

        <BoxedImage src={substackGrantAdminAccessImage} />

        <Button
          block
          css={{ marginTop: "2.5rem" }}
          onClick={checkAdminStatus}
          disabled={onboarding.statusQuery?.isFetching}
        >
          {onboarding.statusQuery?.isFetching
            ? "Verifying admin status..."
            : "I've made Yem App an Admin"}
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SubstackAdminAccessGrant;
