import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import Text from "uikit/text";
import OL from "uikit/orderedList";
import BoxedImage from "uikit/boxedImage";
import substackConnectStep1Image from "assets/images/substack-connect-step-1.png";
import substackConnectStep2Image from "assets/images/substack-connect-step-2.png";
import substackConnectStep3Image from "assets/images/substack-connect-step-3.png";
import CopyBox from "uikit/copyBox";
import Button from "uikit/button";
import { useOnboarding } from "./context";
import Lottie from "react-lottie";
import animationData from "assets/lotties/verifying-invitation-lottie.json";
import { Redirect } from "react-router";
import Alert from "uikit/alert";

const Wrapper = styled("div", {
  padding: "2rem",
  maxWidth: "1280px",
  margin: "0 auto",
});

const ContentWrapper = styled("div", {
  maxWidth: "32rem",
  margin: "0 auto",
  marginTop: "2rem",

  [`& ${OL}`]: {
    marginBottom: "1rem",
  },
});

const Step = styled("div", {
  marginBottom: "1.5rem",
});

const SectionWrapper = styled("div", {
  maxWidth: "37.5rem",
  margin: "0 auto",
});

const YemLogoWrapper = styled("div", {
  height: "3.5rem",
  marginBottom: "2rem",
  svg: {
    position: "fixed",
  },
});

const VerifyingWrapper = styled("div", {
  minHeight: "100vh",
  boxSizing: "border-box",
  maxWidth: "1280px",
  padding: "2rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  "svg.logo": {
    position: "fixed",
    top: "2rem",
  },
});

const VerifyingInvitation: React.FC<{
  onVerificationFailed: () => void;
  setFinished: (finished: boolean) => void;
}> = ({ onVerificationFailed, setFinished }) => {
  const onboarding = useOnboarding();
  const startedAtRef = React.useRef<null | Dayjs>(null);
  const refetchQuery = onboarding.statusQuery!.refetch;
  const invitationStatus = onboarding.statusQuery!.data?.substack.status;

  React.useEffect(() => {
    if (startedAtRef.current === null) {
      startedAtRef.current = dayjs();
    }

    const interval = setInterval(() => {
      const diffSeconds = dayjs().diff(startedAtRef.current!, "second");

      if (diffSeconds > 30) {
        onVerificationFailed();
      }

      refetchQuery();
    }, 1000);

    return () => clearInterval(interval);
  }, [refetchQuery, onVerificationFailed]);

  React.useEffect(() => {
    if (invitationStatus === "admin" || invitationStatus === "invited") {
      window.analytics.track("SubstackInvitationVerification Succeeded");
      setFinished(true);
    }
  }, [invitationStatus, setFinished]);
  return (
    <VerifyingWrapper>
      <YemLogo className="logo" />

      <SectionWrapper>
        <Lottie
          height={160}
          width={160}
          options={{
            animationData,
          }}
        />

        <Text
          weight="bold"
          size="heading5"
          align="center"
          css={{ marginBottom: "1rem" }}
        >
          We're verifying that we received the invitation...
        </Text>

        <Text css={{ color: "$midnight-t20" }} align="center">
          {" "}
          This can take up to 30 seconds.
        </Text>
      </SectionWrapper>
    </VerifyingWrapper>
  );
};

const InviteSubstackAccountPage = () => {
  const [isVerifying, setVerifying] = React.useState(false);
  const [verificationFailed, setVerificationFailed] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const onboarding = useOnboarding();

  React.useEffect(() => {
    window.analytics.track("SubstackInvitationPage Loaded");
  }, []);

  React.useEffect(() => {
    if (isVerifying) {
      window.analytics.track("SubstackInvitationVerification Started");
    }
  }, [isVerifying]);

  const onVerificationFailed = React.useCallback(() => {
    setVerificationFailed(true);
    setVerifying(false);
    window.analytics.track("SubstackInvitationVerification Failed");
  }, []);

  if (onboarding.statusQuery?.data?.substack.status === "admin") {
    return <Redirect to="/onboarding/connect-stripe-account" />;
  }

  if (finished || onboarding.statusQuery?.data?.substack.status === "invited") {
    return <Redirect to="/onboarding/substack-admin-access" />;
  }

  if (isVerifying) {
    return (
      <VerifyingInvitation
        onVerificationFailed={onVerificationFailed}
        setFinished={setFinished}
      />
    );
  }

  return (
    <Wrapper>
      <YemLogoWrapper>
        <YemLogo />
      </YemLogoWrapper>

      <SectionWrapper>
        {verificationFailed && (
          <div
            style={{ width: "32rem", margin: "0 auto", marginBottom: "3rem" }}
          >
            <Alert>
              <Text
                weight="bold"
                size="heading6"
                css={{ marginBottom: "0.5rem", marginTop: "1rem" }}
              >
                We couldn’t find the invitation
              </Text>
              <Text css={{ color: "$midnight-t20", marginBottom: "1.5rem" }}>
                Please verify the invitation was sent correctly.
                <br />
                If so, click Retry.
              </Text>

              <Button block onClick={() => setVerifying(true)}>
                Retry
              </Button>
            </Alert>
          </div>
        )}
        <Text
          size="heading5"
          weight="extraBold"
          align="center"
          css={{ marginBottom: "1.5rem" }}
        >
          Yem connects to your Substack to understand
          <br />
          who to send emails to
        </Text>
        <ContentWrapper>
          <Step>
            <OL number={1}>Go to your writer dashboard and click settings.</OL>
            <BoxedImage src={substackConnectStep1Image} />
          </Step>

          <Step>
            <OL number={2}>
              Scroll down to the team section and click invite.
            </OL>
            <BoxedImage src={substackConnectStep2Image} />
          </Step>

          <Step>
            <OL number={3}>Enter the following email and click “Invite”.</OL>

            <CopyBox
              css={{ marginBottom: "1.5rem" }}
              text={
                onboarding.statusQuery?.data?.substack.invite_email ||
                "Email is loading..."
              }
            />
            <BoxedImage src={substackConnectStep3Image} />
          </Step>

          <Button
            block
            css={{ marginTop: "2rem" }}
            onClick={() => setVerifying(true)}
          >
            Continue
          </Button>
        </ContentWrapper>
      </SectionWrapper>
    </Wrapper>
  );
};

export default InviteSubstackAccountPage;
