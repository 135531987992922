import { styled } from "stitches.config";

const Button = styled("button", {
  backgroundColor: "$midnight",
  color: "white",
  lineHeight: "24px",
  padding: "0.75rem 1.5rem",
  border: "none",
  borderRadius: "$small",
  fontWeight: "$extraBold",
  fontSize: "16px",
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  transitionDuration: "200ms",

  "&:disabled": {
    opacity: 0.6,
    backgroundColor: "$midnight",
    cursor: "not-allowed",
  },

  "&:hover": {
    backgroundColor: "$midnight-t20",
  },

  "&:focus": {
    outline: "none",
    boxShadow: "0px 0px 0px 4px rgba(4, 11, 21, 0.24)",
  },

  "&:active": {
    backgroundColor: "$midnight-t20",
  },

  "&:disabled:hover": {
    opacity: 0.6,
    backgroundColor: "$midnight",
    cursor: "not-allowed",
  },

  variants: {
    link: {
      true: {
        backgroundColor: "transparent",
        color: "$midnight",
        fontWeight: "$medium",

        "&:hover": {
          backgroundColor: "transparent",
          textDecoration: "underline",
        },

        "&:focus": {
          textDecoration: "underline",
          boxShadow: "none",
        },

        "&:active": {
          backgroundColor: "transparent",
        },
      },
    },
    secondary: {
      true: {
        backgroundColor: "$light-neutral-t30",
        color: "$midnight",

        "&:hover": {
          backgroundColor: "$light-neutral-t10",
        },

        "&:focus": {
          boxShadow: "0px 0px 0px 4px rgba(110, 176, 178, 0.25)",
        },

        "&:active": {
          backgroundColor: "$light-neutral",
        },
      },
    },
    size: {
      medium: {
        fontSize: 14,
        lineHeight: "24px",
        padding: "0.5rem 1rem",
      },
    },

    block: {
      true: {
        width: "100%",
      },
    },
  },
});

export default Button;
