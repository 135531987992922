import React from "react";
import { IUser } from "lib/types";

interface IAuthContext {
  user?: IUser;
  token?: string;
  login: (user: IUser, token: string) => void;
  logout: () => void;
  setUser: (user: IUser) => void;
}

const AuthContext = React.createContext<IAuthContext>({
  login: (user: IUser, token: string) => null,
  setUser: (user: IUser) => null,
  logout: () => null,
});

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<IUser | null>(null);
  const [token, setToken] = React.useState<string | undefined>(
    localStorage.authToken
  );

  React.useEffect(() => {
    if (token && localStorage.authToken !== token) {
      localStorage.setItem("authToken", token);
    } else if (!token && localStorage.authToken) {
      localStorage.removeItem("authToken");
    }
  }, [token]);

  React.useEffect(() => {
    if (user) {
      window.analytics.identify(user.analytics_id, {
        firstName: user.first_name,
        lastName: user.last_name,
        publication: user.publication?.name,
        email: user.email,
      });
    }
  }, [user]);

  const login = React.useCallback(
    (store: IUser, token: string) => {
      setUser(store);
      setToken(token);
    },
    [setUser, setToken]
  );
  const logout = React.useCallback(() => {
    setUser(null);
    setToken("");
  }, []);

  const val = React.useMemo(() => {
    return {
      login,
      token,
      setUser,
      logout,
      user: user || undefined,
    };
  }, [user, login, token, setUser, logout]);

  return <AuthContext.Provider value={val}>{children}</AuthContext.Provider>;
};
