import React from "react";
import isHotkey from "is-hotkey";
import { styled } from "stitches.config";
import { createEditor, Descendant, Editor } from "slate";
import { Slate, withReact, Editable } from "slate-react";
import Toolbar from "./toolbar";
import { YemEditor } from "./interface";
import { CustomElement } from "./types";
import { withLists } from "@prezly/slate-lists";
import { lists, listOptions } from "./lists";
import { renderElement, renderLeaf } from "./components";
import { withVoidElements } from "./plugins";
import { withHistory } from "slate-history";
import SubjectEditor from "./subjectEditor";
import { EditorContextProvider } from "./context";

const Wrapper = styled("div", {
  backgroundColor: "$white",
  boxShadow:
    "0px 0px 0px 1px rgba(34, 76, 129, 0.12), 0px 2px 4px rgba(34, 76, 129, 0.08)",
  borderRadius: "$small",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

const ContentWrapper = styled("div", {
  flex: 1,
  minHeight: 0,
  overflow: "auto",
});

const Content = styled("div", {
  padding: "1rem",
  paddingTop: "2rem",
  maxWidth: "34rem",
  margin: "0 auto",
});

const YemEditorComponent: React.FC<{
  value: CustomElement[];
  onChange: (newVal: Descendant[]) => void;
  defaultValue: CustomElement[];
  subject: string;
  onSubjectChange: (newSubject: string) => void;
}> = ({ value, onChange, defaultValue, subject, onSubjectChange }) => {
  // const [value, setValue] = React.useState<Descendant[]>([
  //   {
  //     type: "paragraph",
  //     children: [{ text: "A line of text in a paragraph." }],
  //   },

  //   {
  //     type: "divider",
  //     isVoid: true,
  //     children: [{ text: "" }],
  //   },

  //   {
  //     type: "paragraph",
  //     children: [{ text: "A line of text in a paragraph." }],
  //   },

  //   {
  //     type: "image",
  //     isVoid: true,
  //     children: [{ text: "" }],
  //     src: "http://placehold.it/500x500",
  //   },

  //   {
  //     type: "paragraph",
  //     children: [{ text: "A line of text in a paragraph." }],
  //   },

  //   {
  //     type: "button",
  //     children: [{ text: "" }],
  //     href: "https://google.com",
  //     label: "Go to google",
  //     isVoid: true,
  //   },

  //   {
  //     type: "paragraph",
  //     children: [{ text: "" }],
  //   },
  // ] as CustomElement[]);

  const defaultValueRef = React.useRef<any>(null);
  const editorRef = React.useRef<Editor | null>(null);
  if (!editorRef.current) {
    editorRef.current = withVoidElements(
      withHistory(withReact(withLists(listOptions)(createEditor())))
    );
  }
  const editor = editorRef.current as Editor;

  React.useEffect(() => {
    if (!defaultValueRef.current) {
      defaultValueRef.current = defaultValue;
      return;
    } else if (
      JSON.stringify(defaultValueRef.current) !== JSON.stringify(defaultValue)
    ) {
      defaultValueRef.current = defaultValue;
      onChange(defaultValue);
      editorRef.current!.selection = null;
      editorRef.current!.children = defaultValue;
    }
  }, [defaultValue, onChange]);

  return (
    <EditorContextProvider>
      <Wrapper>
        <Slate value={value} onChange={onChange} editor={editor}>
          <Toolbar />
          <SubjectEditor value={subject} onChange={onSubjectChange} />
          <ContentWrapper>
            <Content>
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={(event) => {
                  const listItemsInSelection = lists.getListItemsInRange(
                    editor,
                    editor.selection
                  );

                  if (isHotkey("tab", event.nativeEvent)) {
                    event.preventDefault();
                    lists.increaseDepth(editor);
                  }

                  if (isHotkey("shift+tab", event.nativeEvent)) {
                    event.preventDefault();
                    lists.decreaseDepth(editor);
                  }

                  if (
                    isHotkey("backspace", event.nativeEvent) &&
                    !lists.canDeleteBackward(editor)
                  ) {
                    event.preventDefault();
                    lists.decreaseDepth(editor);
                  }

                  if (isHotkey("enter", event.nativeEvent)) {
                    if (lists.isCursorInEmptyListItem(editor)) {
                      event.preventDefault();
                      lists.decreaseDepth(editor);
                    } else if (listItemsInSelection.length > 0) {
                      event.preventDefault();
                      lists.splitListItem(editor);
                    }
                  }

                  if (!event.metaKey) {
                    return;
                  }
                  switch (event.key) {
                    // When "B" is pressed, bold the text in the selection.
                    case "b": {
                      event.preventDefault();
                      YemEditor.toggleMark(editor, "bold");
                      break;
                    }
                    case "i": {
                      event.preventDefault();
                      YemEditor.toggleMark(editor, "italic");
                      break;
                    }
                    case "u": {
                      event.preventDefault();
                      YemEditor.toggleMark(editor, "underline");
                      break;
                    }
                  }
                }}
              />
            </Content>
          </ContentWrapper>
        </Slate>
      </Wrapper>
    </EditorContextProvider>
  );
};

export default YemEditorComponent;
