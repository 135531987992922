import { useAPIClient } from "lib/api";
import React from "react";
import { useQuery, UseQueryResult } from "react-query";

interface ISubstackInvitationStatus {
  invite_email: string;
  status: "none" | "admin" | "invited";
}

interface IOnboardingStatus {
  substack: ISubstackInvitationStatus;
  stripe_account: boolean;
}

const OnboardingContext = React.createContext<{
  statusQuery?: UseQueryResult<IOnboardingStatus>;
}>({});

export const useOnboarding = () => React.useContext(OnboardingContext);

export const OnboardingContextProvider: React.FC = ({ children }) => {
  const apiClient = useAPIClient();
  const statusQuery = useQuery("substackInvitationStatus", async () => {
    const response = await apiClient.get<IOnboardingStatus>("/onboarding");
    return response.data;
  });

  const contextValue = React.useMemo(() => {
    return { statusQuery };
  }, [statusQuery]);

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
