import React from "react";
import Button from "uikit/button";
import { Dialog, Title } from "uikit/dialog";
import Text from "uikit/text";

const AllEmailSetupDialog: React.FC<{ onClose: () => void; open?: boolean }> =
  ({ open, onClose }) => {
    return (
      <Dialog onOpenChange={onClose} open={open} width="25rem">
        <Title css={{ marginBottom: "2rem" }}>You are all set!</Title>
        <Text secondary css={{ marginBottom: "1.5rem" }}>
          Emails will start to go out soon, you will receive a weekly
          performance report on your email but you can always visit the stats
          page to see a real time view of Yem's performance.
        </Text>

        <Button block onClick={onClose}>
          Close
        </Button>
      </Dialog>
    );
  };

export default AllEmailSetupDialog;
