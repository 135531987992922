import React, { FormEvent } from "react";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import Text from "uikit/text";
import Field from "uikit/field";
import Input from "uikit/input";
import Button from "uikit/button";
import { useAPIClient } from "lib/api";
import { IUser } from "lib/types";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { useAuth } from "lib/auth";
import { useHistory } from "react-router";

const Wrapper = styled("div", {
  width: "36rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "3rem",
});

const LogoWrapper = styled("div", {
  height: "4.5rem",
  marginBottom: "0.75rem",
});

const Panel = styled("div", {
  backgroundColor: "$cereal-milk-s20",
  padding: "4rem 5.5rem",
  borderRadius: "$large",
});

const Fields = styled("div", {
  marginBottom: "2rem",
});

const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const apiClient = useAPIClient();
  const auth = useAuth();
  const history = useHistory();

  const authenticate = React.useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await apiClient.post<{
        auth_token: string;
        user: IUser;
      }>("/login", {
        email,
        password,
      });

      return response.data;
    },
    [apiClient]
  );

  const loginMutation = useMutation(authenticate);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    loginMutation.mutate(
      {
        email,
        password,
      },
      {
        onSuccess: (data) => {
          auth.login(data.user, data.auth_token);
        },
      }
    );
  };

  const axiosError = loginMutation.error as AxiosError;

  const error = React.useMemo(() => {
    if (axiosError?.response?.data) {
      const errorData: { email?: string; password?: string } =
        axiosError.response.data;
      return errorData;
    }

    return null;
  }, [axiosError?.response?.data]);

  return (
    <Wrapper>
      <LogoWrapper>
        <YemLogo width="94px" height="4.5rem" viewBox="0 0 73 56" />
      </LogoWrapper>

      <Panel>
        <Text
          align="center"
          weight="bold"
          size="heading5"
          css={{ marginBottom: "1.5rem" }}
        >
          Welcome back 👋 — Log in to Yem
        </Text>

        <form onSubmit={handleSubmit}>
          <Fields>
            <Field label="Email" strong error={error?.email && "Invalid email"}>
              <Input
                required
                autoFocus
                size="large"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Field>

            <Field
              label="Password"
              strong
              error={error?.password && "Invalid password"}
            >
              <Input
                size="large"
                required
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Field>
          </Fields>

          <Button
            block
            type="submit"
            disabled={loginMutation.isLoading}
            css={{ marginBottom: "0.5rem" }}
          >
            {loginMutation.isLoading ? "Logging in..." : "Log in"}
          </Button>

          <Button
            block
            link
            type="button"
            onClick={() => history.push("/forgot-password")}
          >
            Forgot your password?
          </Button>
        </form>
      </Panel>
    </Wrapper>
  );
};

export default LoginPage;
