import { ListsOptions, Lists } from "@prezly/slate-lists";

export const listOptions: ListsOptions = {
  defaultBlockType: "paragraph",
  listItemTextType: "list-item-text",
  listItemType: "list-item",
  listTypes: ["unordered-list", "ordered-list"],
  wrappableTypes: ["paragraph"],
};

export const lists = Lists(listOptions);
