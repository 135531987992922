import React, { FunctionComponent } from "react";
import { styled } from "stitches.config";

type TColor = "success" | "danger";

const Root = styled("div", {
  backgroundColor: "$success-t80",
  padding: "2px 0.5rem",
  fontSize: "14px",
  lineHeight: "20px",
  borderRadius: "$medium",

  span: {
    marginLeft: "0.25rem",
    color: "$success-s20",
  },

  variants: {
    color: {
      success: {},
      danger: {
        backgroundColor: "$danger-t90",

        span: {
          color: "$danger-s20",
        },
      },
    },
  },
});

const Badge: React.FC<{ icon?: FunctionComponent; color?: TColor }> = ({
  icon,
  color,
  children,
}) => {
  return (
    <Root color={color}>
      {icon && React.createElement(icon)}
      <span>{children}</span>
    </Root>
  );
};

export default Badge;
