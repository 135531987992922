import React from "react";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import stripeLogoImage from "assets/images/stripe-logo.png";
import { ReactComponent as IntegrationEllipsisIcon } from "assets/vectors/integration-ellipsis.svg";
import Text from "uikit/text";
import Button from "uikit/button";
import { useQueryParams } from "lib/utils";
import { useMutation } from "react-query";
import { useAPIClient } from "lib/api";
import { useHistory } from "react-router";
import { useAuth } from "lib/auth";
import { IUser } from "lib/types";

const Wrapper = styled("div", {
  padding: "2rem",
  maxWidth: "1280px",
  margin: "0 auto",
});

const YemLogoWrapper = styled("div", {
  height: "3.5rem",
  marginBottom: "2rem",
  svg: {
    position: "fixed",
  },
});

const LogosWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "2rem",
});

const YemLogoSquare = styled("div", {
  boxShadow: "0px 4px 8px rgba(205, 206, 208, 0.4)",
  width: "6rem",
  borderRadius: "$medium",
  height: "6rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StripeLogoImg = styled("img", {
  height: "6rem",
  width: "6rem",
});

const EllipsisIconWrapper = styled("div", {
  margin: "0rem 1.5rem",
});

const ContentWrapper = styled("div", {
  width: "25rem",
  margin: "0 auto",
  textAlign: "center",
});

const ConnectStripeAccount = () => {
  const queryParams = useQueryParams();
  const code = queryParams.get("code");
  const apiClient = useAPIClient();
  const auth = useAuth();
  const history = useHistory();

  const { mutate: exchangeStripeCode, isLoading } = useMutation(
    async (code: string) => {
      const response = await apiClient.post<IUser>("/stripe-oauth-exchange", {
        code,
      });

      return response.data;
    },
    {
      onSuccess: (data) => {
        auth.setUser(data);
        history.push("/customize?from_onboarding=1");
      },
    }
  );

  const handleConnect = () => {
    const redirectUri = `${window.location.origin}/onboarding/connect-stripe-account`;
    const url = `https://connect.stripe.com/oauth/authorize?client_id=${process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID}&scope=read_write&response_type=code&redirect_uri=${redirectUri}`;
    window.location.href = url;
  };

  React.useEffect(() => {
    if (code) {
      exchangeStripeCode(code);
    }
  }, [code, exchangeStripeCode]);

  return (
    <Wrapper>
      <YemLogoWrapper>
        <YemLogo />
      </YemLogoWrapper>

      <ContentWrapper>
        <LogosWrapper>
          <YemLogoSquare>
            <YemLogo viewBox="0 0 73 56" />
          </YemLogoSquare>
          <EllipsisIconWrapper>
            <IntegrationEllipsisIcon />
          </EllipsisIconWrapper>
          <StripeLogoImg alt="stripe logo" src={stripeLogoImage} />
        </LogosWrapper>

        <Text weight="bold" size="heading5" css={{ marginBottom: "0.5rem" }}>
          Connect your Stripe account
        </Text>

        <Text secondary size="medium" css={{ marginBottom: "3rem" }}>
          Yem needs access to your stripe account to be able to track things
          like who’s about to cancel, attribution and other relevant information
          to better time messages.
        </Text>

        <Button block onClick={handleConnect} disabled={isLoading}>
          {isLoading ? "Connecting..." : "Connect"}
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ConnectStripeAccount;
