import { styled } from "stitches.config";
import { ReactComponent as WarningIcon } from "assets/vectors/icon-warning.svg";
import { CSS } from "@stitches/react/types/css-util";

const Wrapper = styled("div", {
  backgroundColor: "$tan-t30",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "1.5rem 3.5rem",
  borderRadius: "$large",
  textAlign: "center",
});

const Alert: React.FC<{ css?: CSS }> = ({ css, children }) => {
  return (
    <Wrapper css={css}>
      <WarningIcon />

      {children}
    </Wrapper>
  );
};

export default Alert;
