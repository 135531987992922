import { styled } from "stitches.config";
import {
  DefaultElement,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
} from "slate-react";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { listOptions } from "./lists";
import React from "react";
import * as HoverCard from "uikit/hoverCard";
import { useEditorContext } from "./context";

const StyledText = styled("span", {
  variants: {
    bold: {
      true: {
        fontWeight: "$bold",
      },
    },
    underline: {
      true: {
        textDecoration: "underline",
      },
    },
    italic: {
      true: {
        fontStyle: "italic",
      },
    },

    isLink: {
      true: {
        color: "blue",
        textDecoration: "underline",
      },
    },
  },
});

const StyledElement = styled("div", {
  variants: {
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    fontSize: {
      small: {
        fontSize: 12,
      },
      normal: {
        fontSize: 16,
      },
      large: {
        fontSize: 20,
      },
      huge: {
        fontSize: 32,
      },
    },
  },
});

const StyledHr = styled("hr", {
  border: "solid 1px $cyan",
});

const StyledImg = styled("img", {
  margin: "1rem 0rem",
  maxWidth: "100%",
});

export const EditorButton = styled("div", {
  backgroundColor: "black",
  borderRadius: "$small",
  padding: "12px 24px",
  display: "inline-block",
  color: "white",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "$bold",
  margin: "1rem 0rem",
  userSelect: "none",
});

export const RenderElement: React.FC<RenderElementProps> = (props) => {
  const editor = useSlate();
  const path = ReactEditor.findPath(editor, props.element);
  const editorContext = useEditorContext();
  if (props.element.type === "divider") {
    return (
      <React.Fragment>
        <div>
          <StyledHr {...props.attributes} />
          <div style={{ display: "none" }}>{props.children}</div>
        </div>
      </React.Fragment>
    );
  }

  if (props.element.type === "image") {
    return (
      <React.Fragment>
        <div>
          <StyledImg alt="element" src={props.element.src} />
          <div style={{ display: "none" }}>{props.children}</div>
        </div>
      </React.Fragment>
    );
  }

  if (props.element.type === "button") {
    return (
      <React.Fragment>
        <div contentEditable={false}>
          <HoverCard.Root>
            <HoverCard.Trigger>
              <EditorButton
                css={{ backgroundColor: props.element.color || "#000000" }}
              >
                {props.element.label}
              </EditorButton>
            </HoverCard.Trigger>
            <HoverCard.Content side="top">
              <HoverCard.Item
                onClick={() => editorContext.setButtonToEdit(path)}
              >
                <EditIcon style={{ marginRight: "0.5rem" }} />
                Edit button
              </HoverCard.Item>
            </HoverCard.Content>
          </HoverCard.Root>
          <div style={{ display: "none" }}>{props.children}</div>
        </div>
      </React.Fragment>
    );
  }

  if (props.element.type === listOptions.listTypes[0]) {
    return <ul {...props.attributes}>{props.children}</ul>;
  }

  if (props.element.type === listOptions.listTypes[1]) {
    return <ol {...props.attributes}>{props.children}</ol>;
  }

  if (props.element.type === "list-item") {
    return <li {...props.attributes}>{props.children}</li>;
  }

  return (
    <StyledElement {...props.element}>
      <DefaultElement {...props} />
    </StyledElement>
  );
};

export const renderElement = (props: RenderElementProps) => {
  return <RenderElement {...props} />;
};

export const Leaf: React.FC<RenderLeafProps> = ({
  attributes,
  children,
  leaf,
}) => {
  const isLink = Boolean(leaf.href);

  return (
    <StyledText {...attributes} {...leaf} isLink={isLink}>
      {children}
    </StyledText>
  );
};

export const renderLeaf = (props: any) => {
  return <Leaf {...props} />;
};
