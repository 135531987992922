import { styled } from "stitches.config";

const Toolbar = styled("div", {
  height: "3.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxSizing: "border-box",
  padding: "0rem 2.5rem",
  borderTop: "solid 1px rgba(34, 76, 129, 0.12)",
});

export default Toolbar;
