import React from "react";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import { ReactComponent as CogIcon } from "assets/icons/cog.svg";
import { ReactComponent as BarChartIcon } from "assets/icons/bar-chart.svg";
import { ReactComponent as CustomizeIcon } from "assets/icons/customize.svg";
import { createTeleporter } from "react-teleporter";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "uikit/dropdown";
import { useAuth } from "lib/auth";
import { useLocation } from "react-router";
import { getInitials } from "lib/utils";
import { Link } from "react-router-dom";

export const PageBar = createTeleporter();

const Wrapper = styled("div", {
  boxShadow: "0px 1px 12px 1px rgba(34, 76, 129, 0.12)",
  backgroundColor: "$white",
  zIndex: 1,
  userSelect: "none",
});

const UpperBar = styled("div", {
  height: "4rem",
  boxSizing: "border-box",
  padding: "0rem 2.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Edge = styled("div", {
  width: "10rem",

  variants: {
    justifyEnd: {
      true: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  },
});

const Tabs = styled("div", {
  display: "flex",
  height: "100%",

  a: {
    textDecoration: "none",
    color: "$midnight",
  },
});

const Tab = styled("div", {
  margin: "0rem 1rem",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "$bold",
  display: "flex",
  alignItems: "center",
  height: "100%",
  boxSizing: "border-box",
  marginBottom: 4,
  cursor: "pointer",
  borderBottom: "solid 4px transparent",
  textDecoration: "none",

  "&:hover": {
    borderBottom: "solid 4px $midnight",
  },

  svg: {
    marginRight: "0.5rem",
  },

  variants: {
    active: {
      true: {
        borderBottom: "solid 4px $midnight",
      },
    },
  },
});

const ProfileIcon = styled("div", {
  backgroundColor: "#F4E6D0",
  borderRadius: "50%",
  width: "2.5rem",
  height: "2.5rem",
  fontWeight: "$bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Header = () => {
  const auth = useAuth();
  const location = useLocation();
  return (
    <Wrapper>
      <UpperBar>
        <Edge>
          <YemLogo />
        </Edge>

        <Tabs>
          <Link to="/customize">
            <Tab active={location.pathname.includes("/customize")}>
              <CustomizeIcon />
              Customize
            </Tab>
          </Link>
          <Link to="/stats">
            <Tab active={location.pathname.includes("/stats")}>
              <BarChartIcon />
              Stats
            </Tab>
          </Link>
          <Link to="/settings">
            <Tab active={location.pathname.includes("/settings")}>
              <CogIcon />
              Settings
            </Tab>
          </Link>
        </Tabs>

        <Edge justifyEnd>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <ProfileIcon>
                {getInitials(auth.user!.first_name, auth.user!.last_name)}
              </ProfileIcon>
            </DropdownMenuTrigger>

            <DropdownMenuContent css={{ width: "10.5rem" }} sideOffset={4}>
              {/* <DropdownMenuItem>Account Settings</DropdownMenuItem> */}
              {/* <DropdownMenuItem>Feedback</DropdownMenuItem> */}
              <DropdownMenuItem onClick={() => auth.logout()}>
                Log Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Edge>
      </UpperBar>

      <PageBar.Target />
    </Wrapper>
  );
};

Header.height = "4rem";

export default Header;
