import React from "react";
import { styled } from "stitches.config";
import { QueryClient, QueryClientProvider } from "react-query";
import globalStyles, { authenticatedAppStyles } from "globalStyles";
import AcceptInvitation from "pages/onboarding/acceptInvitation";
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { APIClientProvider, useAPIClient } from "lib/api";
import { AuthProvider, useAuth } from "lib/auth";
import Onboarding from "pages/onboarding";
import Header from "components/header";
import CustomizePage from "pages/customize";
import SignupPage from "pages/onboarding/signup";
import { IUser } from "lib/types";
import SignupWaitlist from "pages/onboarding/signupWaitlist";
import SettingsPage from "pages/settings";
import LoginPage from "pages/auth/login";
import ForgotPasswordPage from "pages/auth/forgotPassword";
import ResetPasswordPage from "pages/auth/resetPassword";
import StatsPage from "pages/stats";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const AuthenticatedAppWrapper = styled("div", {
  height: "100vh",
  display: "flex",
  flexDirection: "column",
});

const AuthenticatedApp = () => {
  const { user, setUser } = useAuth();
  const apiClient = useAPIClient();

  authenticatedAppStyles();

  React.useEffect(() => {
    const fetchUser = async () => {
      const response = await apiClient.get<IUser>("/me");
      setUser(response.data);
    };

    if (!user) {
      fetchUser();
    }
  }, [user, apiClient, setUser]);

  if (!user) {
    return null;
  }

  if (!user.publication || !user.publication.is_onboarded) {
    return <Onboarding />;
  }

  return (
    <AuthenticatedAppWrapper>
      <Header />
      <Switch>
        <Route path="/customize" component={CustomizePage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/stats" component={StatsPage} />
        <Redirect to="/customize" />
      </Switch>
    </AuthenticatedAppWrapper>
  );
};

const App = () => {
  const auth = useAuth();

  if (auth.token) {
    return <AuthenticatedApp />;
  }

  // Unauthenticated app
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/accept-invite/:invitationHash"
          component={AcceptInvitation}
        />

        <Route exact path="/signup" component={SignupPage} />

        <Route exact path="/signup/finished" component={SignupWaitlist} />

        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route
          exact
          path="/reset-password/:passwordResetSecret"
          component={ResetPasswordPage}
        />

        <Redirect to="/login" />
      </Switch>
    </div>
  );
};

const Providers = () => {
  globalStyles();
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <APIClientProvider>
          <Router>
            <App />
          </Router>
        </APIClientProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default Providers;
