import React, { FormEvent } from "react";
import { useSlate } from "slate-react";
import { styled } from "stitches.config";
import Button from "uikit/button";
import ColorInput from "uikit/colorInput";
import { Dialog, DialogButtons, Title } from "uikit/dialog";
import Field from "uikit/field";
import Input from "uikit/input";
import { EditorButton } from "./components";
import { Node, Path, Transforms } from "slate";
import { ButtonElement } from "./types";

const ButtonWrapper = styled("div", {
  backgroundColor: "$light-neutral-t20",
  height: "7rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "$small",
  margin: "1.5rem 0rem",
});

const ButtonModal: React.FC<{
  open: boolean;
  onOpenChange: () => void;
  selectedPath: number;
  buttonToEdit?: Path;
}> = ({ onOpenChange, open, selectedPath, buttonToEdit }) => {
  const editor = useSlate();

  const buttonElement = React.useMemo(() => {
    if (buttonToEdit) {
      return Node.get(editor, buttonToEdit) as ButtonElement;
    }

    return null;
  }, [editor, buttonToEdit]);

  const [text, setText] = React.useState("");
  const [href, setHref] = React.useState("");
  const [color, setColor] = React.useState("#000000");

  React.useEffect(() => {
    if (!open) {
      setText("");
      setHref("");
      setColor("#000000");
    }
  }, [open]);

  React.useEffect(() => {
    if (buttonElement) {
      setText(buttonElement.label);
      setHref(buttonElement.href);
      setColor(buttonElement.color!);
    }
  }, [buttonElement]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (buttonElement) {
      Transforms.setNodes(
        editor,
        {
          ...buttonElement,
          label: text,
          color,
          href,
        },
        {
          at: buttonToEdit,
        }
      );
    } else {
      Transforms.insertNodes(
        editor,
        [
          {
            type: "button",
            children: [{ text: "" }],
            isVoid: true,
            label: text,
            color,
            href,
          },

          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ] as any,
        {
          at: [selectedPath + 1],
        }
      );

      Transforms.select(editor, [selectedPath + 2]);
    }
    onOpenChange();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Title>{buttonElement ? "Edit button" : "Insert button"}</Title>

      <ButtonWrapper>
        <EditorButton css={{ backgroundColor: color }}>
          {text || "Button"}
        </EditorButton>
      </ButtonWrapper>

      <form onSubmit={handleSubmit}>
        <Field label="Text to display">
          <Input
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          />
        </Field>

        <Field label="To what URL should this button refer?">
          <Input
            value={href}
            onChange={(e) => setHref(e.target.value)}
            placeholder="https://google.com"
            type="url"
            required
          />
        </Field>

        <Field label="Background color" horizontal>
          <div style={{ flexBasis: 0, minWidth: "9rem" }}>
            <ColorInput value={color} onChange={setColor} />
          </div>
        </Field>

        <DialogButtons>
          <Button type="button" secondary size="medium" onClick={onOpenChange}>
            Cancel
          </Button>
          <Button css={{ marginLeft: "1rem" }} size="medium" type="submit">
            Done
          </Button>
        </DialogButtons>
      </form>
    </Dialog>
  );
};

export default ButtonModal;
