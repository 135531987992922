import React from "react";
import { PageBar } from "components/header";
import Toolbar from "uikit/toolbar";
import Text from "uikit/text";
import { styled } from "stitches.config";
import { Menu, MenuItem } from "uikit/menu";
import { Switch, Route, Redirect } from "react-router";
import BillingSettings from "./billing";

const Content = styled("div", {
  maxWidth: "57.5rem",
  margin: "0 auto",
  width: "100%",
  paddingTop: "4rem",
  display: "flex",
});

const SettingsPage = () => {
  return (
    <>
      <PageBar.Source>
        <Toolbar>
          <Text weight="bold" size="small">
            Settings
          </Text>
        </Toolbar>
      </PageBar.Source>

      <Content>
        <Menu css={{ marginRight: "5rem" }}>
          <MenuItem active>Billing</MenuItem>
        </Menu>

        <Switch>
          <Route path="/settings/billing" component={BillingSettings} />
          <Redirect to="/settings/billing" />
        </Switch>
      </Content>
    </>
  );
};

export default SettingsPage;
