import { styled } from "stitches.config";

export const MenuItem = styled("div", {
  padding: "0.25rem",
  paddingLeft: "0.75rem",
  marginBottom: "0.25rem",
  fontSize: 16,
  lineHeight: "24px",
  borderRadius: "$small",
  cursor: "pointer",
  fontWeight: "$medium",
  transitionDuration: "200ms",

  "&:hover": {
    backgroundColor: "$light-neutral-t30",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$light-neutral-t20",
      },
    },
  },
});

export const Menu = styled("div", {
  width: "10rem",
});
