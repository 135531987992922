import React from "react";
import axios, { AxiosError } from "axios";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import { styled } from "stitches.config";
import Text from "uikit/text";
import Field from "uikit/field";
import Input from "uikit/input";
import Button from "uikit/button";
import { useLocation, useParams } from "react-router";
import { useAPIClient } from "lib/api";
import ErrorMessage from "uikit/errorMessage";
import { useMutation } from "react-query";
import { IUser } from "lib/types";
import { useAuth } from "lib/auth";

const Wrapper = styled("div", {
  width: "36rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "3rem",
});

const LogoWrapper = styled("div", {
  height: "4.5rem",
  marginBottom: "0.75rem",
});

const Panel = styled("div", {
  backgroundColor: "$cereal-milk-s20",
  padding: "4rem 5.5rem",
  borderRadius: "$large",
});

const Fields = styled("div", {
  marginBottom: "2rem",
});

const AcceptInvitation = () => {
  const location = useLocation();
  const { invitationHash } = useParams<{ invitationHash: string }>();
  const params = new URLSearchParams(location.search);
  const [name, setName] = React.useState(params.get("name") || "");
  const [email, setEmail] = React.useState(params.get("email") || "");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState<{
    non_field_errors?: string[];
    email?: string[];
    invitation_hash?: string[];
  }>({});
  const apiClient = useAPIClient();
  const { login } = useAuth();

  const acceptInvitation = React.useCallback(async () => {
    const [firstName, ...lastNames] = name.split(" ");
    const lastName = lastNames.join(" ");
    try {
      const response = await apiClient.post<{
        auth_token: string;
        user: IUser;
      }>("/invitations/accept", {
        email,
        invitation_hash: invitationHash,
        first_name: firstName,
        last_name: lastName,
        password: password,
      });

      login(response.data.user, response.data.auth_token);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        if (axiosError.response) {
          const errorData = axiosError.response!.data;
          setError(errorData);
        } else {
          setError({ non_field_errors: ["Unknown error"] });
        }
      } else {
        setError({ non_field_errors: ["Unknown error"] });
      }
    }
  }, [name, apiClient, invitationHash, password, email, login]);

  const acceptInvitationMutation = useMutation(acceptInvitation);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      acceptInvitationMutation.mutate();
    },
    [acceptInvitationMutation]
  );

  return (
    <Wrapper>
      <LogoWrapper>
        <YemLogo width="94px" height="4.5rem" viewBox="0 0 73 56" />
      </LogoWrapper>

      <Panel>
        <Text
          align="center"
          weight="bold"
          size="heading5"
          css={{ marginBottom: "0.5rem" }}
        >
          Welcome 👋 — Let’s get started
        </Text>

        <Text align="center" size="medium" css={{ marginBottom: "1.5rem" }}>
          Yem grows your newsletter with
          <br />
          personalized messaging.
        </Text>

        <form onSubmit={handleSubmit}>
          <Fields>
            <Field label="Full name" strong>
              <Input
                required
                size="large"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Field>

            <Field label="Email" strong>
              <Input
                type="email"
                size="large"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Field>

            <Field label="Password" strong>
              <Input
                required
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                autoFocus
              />
            </Field>
            {error.invitation_hash && (
              <ErrorMessage>
                Your invitation has expired or is not valid anymore.
              </ErrorMessage>
            )}

            {error.email && (
              <ErrorMessage>{error.email.join(", ")}</ErrorMessage>
            )}
          </Fields>

          <Button
            block
            type="submit"
            disabled={acceptInvitationMutation.isLoading}
          >
            {acceptInvitationMutation.isLoading
              ? "Creating account..."
              : "Create account"}
          </Button>
        </form>
      </Panel>
    </Wrapper>
  );
};

export default AcceptInvitation;
