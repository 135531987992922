import React, { FormEvent } from "react";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import Text from "uikit/text";
import Field from "uikit/field";
import Input from "uikit/input";
import Button from "uikit/button";
import { styled } from "stitches.config";
import { useAPIClient } from "lib/api";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

const Wrapper = styled("div", {
  width: "36rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "3rem",
});

const LogoWrapper = styled("div", {
  height: "4.5rem",
  marginBottom: "0.75rem",
});

const Panel = styled("div", {
  backgroundColor: "$cereal-milk-s20",
  padding: "4rem 5.5rem",
  borderRadius: "$large",
});

const Fields = styled("div", {
  marginBottom: "2rem",
});

const ForgotPasswordPage = () => {
  const [email, setEmail] = React.useState("");
  const [finished, setFinished] = React.useState(false);
  const apiClient = useAPIClient();

  const requestPasswordReset = React.useCallback(
    async (email: string) => {
      return apiClient.post("/password-reset-request", {
        email,
      });
    },
    [apiClient]
  );

  const requestPasswordResetMutation = useMutation(requestPasswordReset);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    requestPasswordResetMutation.mutate(email, {
      onSuccess: () => {
        setFinished(true);
      },
    });
  };

  const axiosError = requestPasswordResetMutation.error as AxiosError;

  const error = React.useMemo(() => {
    if (axiosError?.response?.data) {
      const errorData: { email?: string } = axiosError.response.data;

      return errorData;
    }

    return null;
  }, [axiosError?.response?.data]);

  return (
    <Wrapper>
      <LogoWrapper>
        <YemLogo width="94px" height="4.5rem" viewBox="0 0 73 56" />
      </LogoWrapper>

      <Panel>
        {finished && (
          <>
            <Text
              align="center"
              weight="bold"
              size="heading5"
              css={{ marginBottom: "0.5rem" }}
            >
              Email sent
            </Text>

            <Text align="center" size="small">
              Check your email and open the link we sent to continue.
            </Text>
          </>
        )}
        {!finished && (
          <>
            <Text
              align="center"
              weight="bold"
              size="heading5"
              css={{ marginBottom: "0.5rem" }}
            >
              Forgot password?
            </Text>

            <Text
              align="center"
              size="small"
              css={{ padding: "0rem 1.5rem", marginBottom: "1.5rem" }}
            >
              Enter the email address you used when you first signed up. We’ll
              send you instructions to reset your password.
            </Text>

            <form onSubmit={handleSubmit}>
              <Fields>
                <Field
                  label="Email"
                  strong
                  error={
                    error?.email && "We couldn't find a user with this email"
                  }
                >
                  <Input
                    required
                    autoFocus
                    size="large"
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Field>
              </Fields>

              <Button
                block
                type="submit"
                disabled={requestPasswordResetMutation.isLoading}
              >
                {requestPasswordResetMutation.isLoading
                  ? "Submitting..."
                  : "Send reset instructions"}
              </Button>
              <Button block link type="button">
                Back to Login
              </Button>
            </form>
          </>
        )}
      </Panel>
    </Wrapper>
  );
};

export default ForgotPasswordPage;
