import { Editor } from "slate";

export const withVoidElements = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    if ((element as any).isVoid) {
      return true;
    }
    return isVoid(element);
  };

  return editor;
};
