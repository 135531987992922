import * as HC from "@radix-ui/react-hover-card";
import { styled } from "stitches.config";

export const Root = HC.Root;
export const Trigger = styled(HC.Trigger, {
  display: "inline-block",
});
export const Content = styled(HC.Content, {
  backgroundColor: "white",
  boxShadow: "0px 0px 0px 1px #E4EAF0, 0px 2px 12px rgba(34, 76, 129, 0.12)",
  borderRadius: 4,
  padding: "0.25rem",
});

export const Item = styled("div", {
  display: "flex",
  alignItems: "center",
  padding: "0.5rem",
  borderRadius: 4,
  cursor: "pointer",
  "&:hover": {
    outline: "none",
    backgroundColor: "$light-neutral-t30",
  },
});
