import { globalCss } from "stitches.config";
import eudoxusSansMediumWoff from "assets/fonts/EudoxusSans-Medium.woff";
import eudoxusSansMediumWoff2 from "assets/fonts/EudoxusSans-Medium.woff2";
import eudoxusSansBoldWoff from "assets/fonts/EudoxusSans-Bold.woff";
import eudoxusSansBoldWoff2 from "assets/fonts/EudoxusSans-Bold.woff2";
import eudoxusSansExtraBoldWoff from "assets/fonts/EudoxusSans-ExtraBold.woff";
import eudoxusSansExtraBoldWoff2 from "assets/fonts/EudoxusSans-ExtraBold.woff2";

export const stripeFontFamilies = [
  {
    family: "EudoxusSans",
    src: `url('https://d3pxwn1r0hxrud.cloudfront.net/EudoxusSans-Regular.woff2') format("woff2")`,
  },
];

const globalStyles = globalCss({
  "@font-face": [
    {
      fontFamily: "EudoxusSans",
      src: `url(${eudoxusSansMediumWoff}) format("woff")`,
      fontWeight: 600,
    },
    {
      fontFamily: "EudoxusSans",
      src: `url(${eudoxusSansMediumWoff2}) format("woff2")`,
      fontWeight: 600,
    },
    {
      fontFamily: "EudoxusSans",
      src: `url(${eudoxusSansBoldWoff}) format("woff")`,
      fontWeight: 700,
    },
    {
      fontFamily: "EudoxusSans",
      src: `url(${eudoxusSansBoldWoff2}) format("woff2")`,
      fontWeight: 700,
    },
    {
      fontFamily: "EudoxusSans",
      src: `url(${eudoxusSansExtraBoldWoff}) format("woff")`,
      fontWeight: 800,
    },
    {
      fontFamily: "EudoxusSans",
      src: `url(${eudoxusSansExtraBoldWoff2}) format("woff2")`,
      fontWeight: 800,
    },
  ],
  html: {
    fontSize: 16,
    "-webkit-font-smoothing": "antialiased",
  },
  body: {
    fontFamily: "EudoxusSans",
    backgroundColor: "$cereal-milk",
    margin: 0,
  },
  "button,textarea,input": {
    fontFamily: "inherit",
  },
  "#root.blurred": {
    filter: "blur(5px)",
  },
});

export const authenticatedAppStyles = globalCss({
  body: {
    backgroundColor: "#FFFFFF",
  },
});

export default globalStyles;
