import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import { styled } from "stitches.config";

export const ToggleGroupRoot = styled(RadixToggleGroup.Root, {
  border: "solid 1px $late-night-t80",
  borderRadius: "0.5rem",
  display: "inline-flex",
  color: "$midnight-t50",
});

export const ToggleGroupItem = styled(RadixToggleGroup.Item, {
  padding: "10px 12px",
  backgroundColor: "transparent",
  border: "none",
  borderRight: "solid 1px $late-night-t80",
  cursor: "pointer",
  transitionDuration: "200ms",

  "&:first-child": {
    borderLeft: "none",
  },

  "&:last-child": {
    borderRight: "none",
  },

  "&:hover": {
    backgroundColor: "$light-neutral-t30",
  },

  "&[data-state=on]": {
    backgroundColor: "$light-neutral-t20",
    fontWeight: "$bold",
    color: "$midnight",
  },
});
