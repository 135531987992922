import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { SketchPicker } from "react-color";

import { styled } from "stitches.config";

const Wrapper = styled("div", {
  lineHeight: "24px",
  backgroundColor: "white",
  border: "1px solid $midnight-t80",
  boxSizing: "border-box",
  fontSize: "$normal",
  borderRadius: "$small",
  width: "100%",
  display: "flex",
  alignItems: "center",
  transitionDuration: "200ms",
  padding: "0.5rem",
  position: "relative",

  input: {
    flex: 1,
    minWidth: 0,
    border: "none",
    marginLeft: "0.5rem",
    fontSize: 14,
    lineHeight: "24px",

    "&:focus": {
      outline: "none",
    },
  },
});

const Label = styled("div", {
  fontSize: 14,
  lineHeight: "24px",
  marginLeft: "0.5rem",
});

const ColorBox = styled("div", {
  borderRadius: 4,
  border: "solid 1px $midnight-t80",
  width: "1.5rem",
  height: "1.5rem",
});

const PickerWrapper = styled("div", {
  position: "absolute",
  left: "calc(100% + 0.5rem)",
  bottom: "0",
});

const ColorInput: React.FC<{
  value: string;
  onChange: (newColor: string) => void;
}> = ({ value, onChange }) => {
  const [pickerOpen, setPickerOpen] = React.useState(false);
  return (
    <Wrapper
      onClick={() => {
        setPickerOpen(true);
      }}
    >
      <ColorBox css={{ backgroundColor: value }} />

      <Label>{value}</Label>
      <OutsideClickHandler onOutsideClick={() => setPickerOpen(false)}>
        {pickerOpen && (
          <PickerWrapper>
            <SketchPicker
              color={value}
              onChange={(color) => onChange(color.hex)}
            />
          </PickerWrapper>
        )}
      </OutsideClickHandler>
    </Wrapper>
  );
};

export default ColorInput;
