import React from "react";
import Text from "uikit/text";

const OnboardingUpsellHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Onboarding upsell
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Convert free subs to paid subs
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> All free subscribers
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 7 days after signup
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        Subscribers may become used to seeing the same upsell message in the
        newsletter, glossing over it to start reading the content. We've seen
        value in sending a dedicated free-to-paid upsell message separate from
        the core newsletter. This provides a chance to focus on a clear and
        concise summary of your value proposition.
      </Text>

      <Text size="heading6" weight="bold">
        Tips
      </Text>

      <ul>
        <li>Keep it short (&lt; 250 words)</li>
        <li>Have one clear call-to-action (buttons are usually the best)</li>
        <li>
          Use images / emojis to visualize the benefits of a paid subscription
        </li>
        <li>
          If there’s more than a few benefits of becoming a paying subscriber,
          consider listing them as bullets under the call-to-action
        </li>
        <li>
          If applicable, mention that some subs expense their subscription as
          part of learning and development budget
        </li>
        <li>
          If it’s more a patronage model, emphasize the impact of your work
        </li>
      </ul>
    </>
  );
};

const OnboardingUpsellSecondHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Onboarding upsell 2
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Convert free subs to paid subs
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> All free subscribers
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 28 days after signup
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        For newsletters that have been around for a few years, of all their
        paying subscribers, 60% to 70% become paid within the first 30 days. The
        first month is an important time to drive engagement and free-to-paid
        conversion. This upsell email offers a discount to free subscribers
        right before they exit their first month.
      </Text>

      <Text size="heading6" weight="bold">
        Tips
      </Text>

      <ul>
        <li>Keep it short (&lt; 250 words)</li>
        <li>Emphasize special offer (suggest b/w 20% - 30% discount)</li>
        <li>One clear call-to-action (buttons are usually the best)</li>
        <li>
          Consider a reminder section for the benefits of a paid subscription
        </li>
      </ul>
    </>
  );
};

const SetToCancelHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Set to cancel
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Retain existing paid subscribers
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> Paid subscribers that have turned
          off auto-renew
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 4 days before subscription expires
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        We’re seeing an increase in paid subs that cancel right after signing
        up. In their feedback, they mention being open to renewing, but not
        wanting to do so without awareness. For those subs (and others), this
        message is a gentle reminder that their subscription is about to expire.
      </Text>

      <Text size="heading6" weight="bold">
        Tips
      </Text>

      <ul>
        <li>Focus call-to-action on setting subscription to renew</li>
        <li>Remind paid subs of the benefits they will lose access to</li>
      </ul>
    </>
  );
};

const PaymentFailureHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Payment Failure
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Retain existing paid subscribers
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> Paid subs with a payment failure on
          renewal off auto-renew
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 5 days after first payment failure
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        Payment failures are a huge issue for every digital subscription
        product. These subscribers haven’t chosen to cancel, but are at risk of
        lapsing. The most impactful way to reduce payment is letting subscribers
        know their payment method isn’t working and asking them to update it.
      </Text>
    </>
  );
};

const PaymentFailureSecondHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Payment Failure
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Retain existing paid subscribers
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> Paid subs with a payment failure on
          renewal off auto-renew
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 25 days after first payment failure
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        This serves as one last reminder before their subscription expires.
      </Text>
    </>
  );
};

const WinbackHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Winback
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Convert free subs to paid subs
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> Former paid subscribers
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 60 days after expiration
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        Over time, former paying subscribers become an increasingly important
        source for subscriber acquisition. After testing different winback
        campaigns, we found most subs that become paid again do so around 60
        days after their most recent subscription expired.
      </Text>

      <Text size="heading6" weight="bold">
        Tips
      </Text>

      <ul>
        <li>
          Keep it short - former subs will likely be familiar with your value
          prop
        </li>
        <li>Let them know you miss them and want to welcome them back 🤗</li>
        <li>Emphasize any recent benefits added or product improvements</li>
        <li>
          Emphasize any special offers (suggest between 20% - 30% discount)
        </li>
      </ul>
    </>
  );
};

const TopPostsHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Top posts
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Reduce dormancy
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> All subscribers
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> 2 days after Sign-up
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        We see between 40% to 60% of email lists at risk of going dormant (over
        30 days of no activity). Engaging subs early on reduces the risk of
        dormancy. This message highlights top posts to give new subs a sense of
        what to expect and make them excited to read the next post. The
        onboarding engagement message is especially helpful for less frequent
        publishers (including less frequent posts for free subs).
      </Text>

      <Text size="heading6" weight="bold">
        Tips
      </Text>

      <ul>
        <li>
          Including compelling images / keyart and a summary is helpful, but a
          simple list of articles works as well
        </li>
      </ul>
    </>
  );
};

const ScoreBasedUpsellHelp = () => {
  return (
    <>
      <Text size="heading6" weight="bold">
        Score-based upsell
      </Text>

      <div style={{ margin: "1rem 0rem" }}>
        <Text size="small">
          <strong>Goal:</strong> Convert free subs to paid subs
        </Text>

        <Text size="small">
          <strong>Who it's sent to:</strong> All free subs excluding those who
          are part of other campaigns (winback, other upsell emails)
        </Text>

        <Text size="small">
          <strong>When it's sent:</strong> Every day to a rotating segment
          (0.5%-2% of subs) based on scoring algorithm
        </Text>
      </div>

      <Text size="heading6" weight="bold">
        Context
      </Text>

      <Text size="small" css={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        Subscribers may become used to seeing the same upsell message in the
        newsletter, glossing over it to start reading the content. We've seen
        value in sending a dedicated free-to-paid upsell message separate from
        the core newsletter. It’s also helpful to provide a little extra
        incentive to increase conversion.
      </Text>

      <Text size="heading6" weight="bold">
        Tips
      </Text>

      <ul>
        <li>
          If you want to provide an incentive (like a discount), the copy can be
          nearly identical to second onboarding upsell
        </li>

        <li>
          If you don’t want to offer an incentive, the copy can be nearly
          identical to first onboarding upsell
        </li>
      </ul>
    </>
  );
};

export const lifecycleCampaignsNames: { [key: string]: string } = {
  onboarding_upsell: "Onboarding Upsell",
  onboarding_upsell_second: "Onboarding Upsell 2",
  set_to_cancel: "Set to cancel",
  payment_failure: "Payment Failure",
  payment_failure_2: "Payment Failure #2",
  winback: "Winback",
  top_posts: "Top posts",
  score_based_upsell: "Score-based upsell",
};

export const lifecycleCampaignsGoal: { [key: string]: string } = {
  onboarding_upsell: "Conversion",
  onboarding_upsell_second: "Conversion",
  set_to_cancel: "Retention",
  payment_failure: "Retention",
  payment_failure_2: "Retention",
  winback: "Conversion",
  top_posts: "Engagement",
  score_based_upsell: "Conversion",
};

const lifecycleCampaignsHelp: { [key: string]: React.FC } = {
  onboarding_upsell: OnboardingUpsellHelp,
  onboarding_upsell_second: OnboardingUpsellSecondHelp,
  set_to_cancel: SetToCancelHelp,
  payment_failure: PaymentFailureHelp,
  payment_failure_2: PaymentFailureSecondHelp,
  winback: WinbackHelp,
  top_posts: TopPostsHelp,
  score_based_upsell: ScoreBasedUpsellHelp,
};

export default lifecycleCampaignsHelp;
