import React from "react";
import Text from "uikit/text";
import { styled } from "stitches.config";
import { CSS } from "@stitches/react/types/css-util";

const Wrapper = styled("div", {
  fontSize: "$normal",
  border: "solid 1px $midnight",
  borderRadius: "$small",
  backgroundColor: "$cereal-milk-s20",
  display: "flex",
  justifyContent: "space-between",
  cursor: "text",

  button: {
    backgroundColor: "$midnight",
    cursor: "pointer",
    outline: "none",
    border: "none",
    color: "white",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "0px 7px 7px 0px",
    padding: "0.75rem 1.5rem",
  },
});

const CopyBox: React.FC<{ text: string; css?: CSS }> = ({ text, css }) => {
  const [copied, setCopied] = React.useState(false);

  const handleClick = React.useCallback(() => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }, [text]);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [setCopied, copied]);

  return (
    <Wrapper css={css} onClick={handleClick}>
      <Text weight="medium" css={{ lineHeight: "24px", padding: "0.75rem" }}>
        {text}
      </Text>

      <button>{copied ? "Copied!" : "Copy"}</button>
    </Wrapper>
  );
};

export default CopyBox;
