import { Editor } from "slate";
import { Mark } from "./types";

export const YemEditor = {
  hasMark(editor: Editor, mark: Mark) {
    const result = Editor.marks(editor);

    if (result) {
      return result[mark];
    }

    return false;
  },

  toggleMark(editor: Editor, mark: Mark) {
    const hasMark = YemEditor.hasMark(editor, mark);
    editor.addMark(mark, !hasMark);
  },
};
