import React from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { styled } from "stitches.config";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { CSS } from "@stitches/react";

const Overlay = styled(RadixDialog.Overlay, {
  backgroundColor: "$midnight",
  position: "fixed",
  opacity: 0.32,
  inset: 0,
});

const Content = styled(RadixDialog.Content, {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "$white",
  outline: "none",
  width: "37.5rem",
  maxWidth: "90vw",
  borderRadius: "$small",
  padding: "1.5rem",
});

const CloseButtonWrapper = styled(RadixDialog.DialogClose, {
  padding: 0,
  backgroundColor: "transparent",
  border: "none",
  outline: "none",
  display: "flex",
  cursor: "pointer",
});

const StyledTitle = styled(RadixDialog.Title, {
  fontSize: "$heading6",
  margin: 0,
});

const TitleWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const DialogButtons = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "2.5rem",
});

export const Title: React.FC<{ css?: CSS }> = ({ children, css }) => {
  return (
    <TitleWrapper css={css}>
      <StyledTitle>{children}</StyledTitle>
      <CloseButtonWrapper>
        <CloseIcon />
      </CloseButtonWrapper>
    </TitleWrapper>
  );
};

export const Dialog: React.FC<{
  open?: boolean;
  onOpenChange: (open: boolean) => void;
  width?: string;
  overlayStyle?: CSS;
  blurredBackground?: boolean;
}> = ({
  children,
  open,
  onOpenChange,
  width,
  overlayStyle,
  blurredBackground,
}) => {
  const isBlurredRef = React.useRef(false);

  React.useEffect(() => {
    if (blurredBackground && open) {
      isBlurredRef.current = true;
      document.querySelector("#root")!.classList.add("blurred");
    }

    if (!open && isBlurredRef.current) {
      document.querySelector("#root")?.classList.remove("blurred");
    }

    return () => {
      if (isBlurredRef.current) {
        document.querySelector("#root")?.classList.remove("blurred");
      }
    };
  }, [blurredBackground, open]);

  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      <Overlay css={overlayStyle} className="overlay-yo" />
      <Content css={{ width }}>{children}</Content>
    </RadixDialog.Root>
  );
};
