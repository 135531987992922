import React, { FormEvent } from "react";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import Text from "uikit/text";
import Field from "uikit/field";
import Input from "uikit/input";
import Button from "uikit/button";
import { styled } from "stitches.config";
import { useAPIClient } from "lib/api";
import { IUser } from "lib/types";
import { useMutation } from "react-query";
import { RouteComponentProps } from "react-router";
import { useAuth } from "lib/auth";

const Wrapper = styled("div", {
  width: "36rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "3rem",
  boxSizing: "border-box",
});

const LogoWrapper = styled("div", {
  height: "4.5rem",
  marginBottom: "0.75rem",
});

const Panel = styled("div", {
  backgroundColor: "$cereal-milk-s20",
  padding: "4rem 5.5rem",
  borderRadius: "$large",
  width: "100%",
  boxSizing: "border-box",
});

const Fields = styled("div", {
  marginBottom: "2rem",
});

const ResetPasswordPage: React.FC<
  RouteComponentProps<{ passwordResetSecret: string }>
> = ({ match }) => {
  const [password, setPassword] = React.useState("");
  const auth = useAuth();
  const apiClient = useAPIClient();

  const resetPassword = React.useCallback(
    async ({ secret, password }: { secret: string; password: string }) => {
      const response = await apiClient.post<{
        auth_token: string;
        user: IUser;
      }>("/reset-password", {
        secret,
        password,
      });

      return response.data;
    },
    [apiClient]
  );

  const resetPasswordMutation = useMutation(resetPassword);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    resetPasswordMutation.mutate(
      {
        password,
        secret: match.params.passwordResetSecret,
      },
      {
        onSuccess: (data) => {
          auth.login(data.user, data.auth_token);
        },
      }
    );
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <YemLogo width="94px" height="4.5rem" viewBox="0 0 73 56" />
      </LogoWrapper>

      <Panel>
        <Text
          align="center"
          weight="bold"
          size="heading5"
          css={{ marginBottom: "1.5rem" }}
        >
          Reset password
        </Text>

        <form onSubmit={handleSubmit}>
          <Fields>
            <Field label="New password" strong>
              <Input
                required
                autoFocus
                size="large"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Field>
          </Fields>

          <Button block disabled={resetPasswordMutation.isLoading}>
            {resetPasswordMutation.isLoading
              ? "Resetting..."
              : "Reset password"}
          </Button>
        </form>
      </Panel>
    </Wrapper>
  );
};

export default ResetPasswordPage;
