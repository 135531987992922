import React from "react";
import { styled } from "stitches.config";
import { ReactComponent as OrderedListBg } from "assets/vectors/ordered-list-bg.svg";
import Text from "uikit/text";

const OLWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const OLNumberWrapper = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  marginRight: "0.5rem",
  svg: {
    position: "absolute",
    zIndex: -1,
  },
});

const OL: React.FC<{ number: number }> = ({ number, children }) => {
  return (
    <OLWrapper>
      <OLNumberWrapper>
        <OrderedListBg />
        {number}
      </OLNumberWrapper>
      <Text size="medium" weight="medium" css={{ color: "$midnight-t20" }}>
        {children}
      </Text>
    </OLWrapper>
  );
};

OL.toString = OLWrapper.toString;

export default OL;
