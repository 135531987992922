import { styled } from "stitches.config";

const ErrorMessage = styled("div", {
  padding: "1rem 0rem",
  color: "$danger",
  fontSize: "$normal",
  fontWeight: "$medium",
});

export default ErrorMessage;
