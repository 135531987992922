import React from "react";
import Header from "components/header";
import { Transition } from "react-transition-group";
import { styled } from "stitches.config";
import Text from "uikit/text";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ILifecycleCampaign } from "lib/types";
import { useHistory } from "react-router";
import { Dialog, DialogButtons, Title } from "uikit/dialog";
import Button from "uikit/button";
import {
  lifecycleCampaignsGoal,
  lifecycleCampaignsNames,
} from "./lifecycleCampaignsHelp";

const Overlay = styled("div", {
  position: "fixed",
  backgroundColor: "$midnight",
  opacity: 0.32,
  inset: 0,
  top: Header.height,
  transitionDuration: "300ms",
});

const Drawer = styled("div", {
  position: "fixed",
  left: 0,
  top: Header.height,
  bottom: 0,
  backgroundColor: "white",
  width: "20rem",
  transitionDuration: "300ms",
  display: "flex",
  flexDirection: "column",
});

const DrawerHeader = styled("div", {
  padding: "1rem 0rem",
  paddingLeft: "2.5rem",
  paddingRight: "1rem",
  borderBottom: "solid 1px rgba(34, 76, 129, 0.12)",
  borderTop: "solid 1px rgba(34, 76, 129, 0.12)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const CloseIconWrapper = styled("div", {
  display: "flex",
  cursor: "pointer",
});

const Flex = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const overlayStyles: any = {
  entering: {
    display: "block",
    opacity: 0,
  },
  entered: {
    display: "block",
    opacity: 0.32,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    display: "none",
    opacity: 0,
  },
};

const drawerStyles: any = {
  entering: {
    transform: "translateX(-100%)",
  },
  entered: {
    transform: "translateX(0%)",
  },
  exiting: {
    transform: "translateX(-100%)",
  },
  exited: {
    display: "none",
  },
};

const Email = styled("div", {
  padding: "1rem 1.5rem 1rem 2.5rem",
  borderBottom: "solid 1px rgba(34, 76, 129, 0.12)",
  cursor: "pointer",
  transitionDuration: "200ms",

  "&:hover": {
    backgroundColor: "$light-neutral-t30",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$light-neutral-t20",

        "&:hover": {
          backgroundColor: "$light-neutral-t20",
        },
      },
    },
    completed: {
      true: {
        backgroundColor: "#F1F7F1",
        "&:hover": {
          backgroundColor: "#F1F7F1",
        },
      },
    },
  },
});

const Emails = styled("div", {
  flex: 1,
  minHeight: 0,
  overflow: "auto",
});

const EmailSelectionDrawer: React.FC<{
  open: boolean;
  onExit: () => void;
  lifecycleCampaigns: ILifecycleCampaign[];
  selectedCampaignId: string;
  hasUnsavedChanges: boolean;
  onSave: () => Promise<any>;
  isSaving: boolean;
  isSettingUpEmailsForTheFirstTime: boolean;
}> = ({
  open,
  onExit,
  lifecycleCampaigns,
  selectedCampaignId,
  hasUnsavedChanges,
  isSettingUpEmailsForTheFirstTime,
  onSave,
  isSaving,
}) => {
  const history = useHistory();
  const [nextCampaignId, setNextCampaignId] = React.useState<number | null>(
    null
  );

  // Need this ref for this issue -> https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef<any>(null);

  const handleEmailSelected = (lifecycleCampaignId: number) => {
    if (hasUnsavedChanges) {
      setNextCampaignId(lifecycleCampaignId);
    } else {
      history.push(`/customize/${lifecycleCampaignId}`);
      onExit();
    }
  };

  const handleSaveUnsavedChanges = async () => {
    await onSave();
    history.push(`/customize/${nextCampaignId}`);
    setNextCampaignId(null);
    onExit();
  };

  return (
    <Transition
      nodeRef={nodeRef}
      in={open}
      timeout={{
        appear: 0,
        enter: 0,
        exit: 300,
      }}
    >
      {(state) => (
        <>
          <Overlay style={overlayStyles[state]} onClick={onExit} />
          <Drawer style={drawerStyles[state]}>
            <DrawerHeader>
              <Text weight="bold">Emails</Text>

              <CloseIconWrapper onClick={onExit}>
                <CloseIcon />
              </CloseIconWrapper>
            </DrawerHeader>

            <Emails>
              {lifecycleCampaigns.map((lifecycleCampaign) => {
                const completed = lifecycleCampaign.active;
                return (
                  <Email
                    key={lifecycleCampaign.id}
                    active={Boolean(
                      lifecycleCampaign.id.toString() === selectedCampaignId
                    )}
                    completed={isSettingUpEmailsForTheFirstTime && completed}
                    onClick={() => handleEmailSelected(lifecycleCampaign.id)}
                  >
                    <Flex css={{ marginBottom: "0.25rem" }}>
                      <Text weight="bold" size="small">
                        {lifecycleCampaignsNames[lifecycleCampaign.name] ||
                          lifecycleCampaign.name}
                      </Text>
                      {isSettingUpEmailsForTheFirstTime && completed && (
                        <Flex>
                          <CheckIcon />
                          <Text
                            size="tiny"
                            weight="medium"
                            css={{ marginLeft: "0.25rem" }}
                          >
                            Completed
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                    <Text size="small" secondary>
                      {lifecycleCampaignsGoal[lifecycleCampaign.name]}
                    </Text>
                  </Email>
                );
              })}
            </Emails>
          </Drawer>
          <Dialog
            width="28rem"
            open={nextCampaignId !== null}
            onOpenChange={() => setNextCampaignId(null)}
          >
            <Title css={{ marginBottom: "1.5rem" }}>
              Want to save your changes?
            </Title>

            <Text>
              You will lose any changes if you continue without saving them.
            </Text>

            <DialogButtons>
              <Button
                css={{ marginRight: "1rem" }}
                secondary
                onClick={() => {
                  history.push(`/customize/${nextCampaignId}`);
                  setNextCampaignId(null);
                  onExit();
                }}
              >
                Discard
              </Button>
              <Button onClick={handleSaveUnsavedChanges} disabled={isSaving}>
                {isSaving ? "Saving..." : "Save"}
              </Button>
            </DialogButtons>
          </Dialog>
        </>
      )}
    </Transition>
  );
};

export default EmailSelectionDrawer;
