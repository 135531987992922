import React, { FormEvent } from "react";
import { styled } from "stitches.config";
import Text from "uikit/text";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeFontFamilies } from "globalStyles";
import Button from "uikit/button";
import { useAPIClient } from "lib/api";
import { ISubscription } from "lib/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import { capitalizeString, numberInTwoDigits } from "lib/utils";

const Panel = styled("div", {
  boxShadow: "0px 0px 0px 1px rgba(34, 76, 129, 0.12)",
  flex: 1,
  borderRadius: "$small",
  padding: "1.5rem",
});

const Strong = styled("span", {
  fontWeight: "$bold",
});

const Wrapper = styled("div", {
  flex: 1,
  [Panel.toString()]: {
    marginBottom: "1.5rem",

    "&:last-child": {
      marginBottom: "0rem",
    },
  },
});

const CardInputWrapper = styled("div", {
  border: "solid 1px $midnight-t80",
  borderRadius: "$small",
  padding: "0.5rem 0.75rem",
  maxWidth: "25rem",
  marginBottom: "1.5rem",
});

const Card = styled("div", {
  display: "flex",
  alignItems: "center",
});

const BillingSettings = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = React.useState(false);
  const apiClient = useAPIClient();
  const queryClient = useQueryClient();

  const fetchBillingDetails = React.useCallback(async () => {
    const response = await apiClient.get<{ subscription: ISubscription }>(
      "/billing"
    );

    return response.data.subscription;
  }, [apiClient]);

  const addCard = React.useCallback(
    async (paymentMethodId: string) => {
      const response = await apiClient.post<ISubscription>(
        "/billing/add-card",
        {
          payment_method_id: paymentMethodId,
        }
      );

      return response.data;
    },
    [apiClient]
  );

  const subscriptionQuery = useQuery("billing", fetchBillingDetails);
  const addCardMutation = useMutation(addCard, {
    onSuccess: (result) => {
      queryClient.setQueryData("billing", result);
    },
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setSubmitting(true);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("card")!,
    });

    if (result.error) {
      return setSubmitting(false);
    }

    addCardMutation.mutate(result.paymentMethod!.id);

    setSubmitting(false);
  };

  if (subscriptionQuery.isLoading) {
    return null;
  }

  const isAddCardMutationLoading = submitting || addCardMutation.isLoading;

  return (
    <Wrapper>
      {subscriptionQuery.data! ? (
        <>
          <Panel>
            <Text weight="bold" css={{ marginBottom: "1.5rem" }}>
              Subscription
            </Text>

            {subscriptionQuery.data!.trial_end && (
              <Text css={{ marginBottom: "0.5rem" }}>
                You are on a free trial of Yem that ends on{" "}
                <Strong>
                  {dayjs(subscriptionQuery.data!.trial_end).format(
                    "MMMM D, YYYY"
                  )}
                </Strong>
                .
              </Text>
            )}

            {!subscriptionQuery.data!.trial_end && (
              <Text css={{ marginBottom: "0.5rem" }}>
                You have an active Yem subscription that will renew on{" "}
                <Strong>
                  {dayjs(subscriptionQuery.data!.current_period_end).format(
                    "MMMM D, YYYY"
                  )}
                </Strong>
                .
              </Text>
            )}

            {subscriptionQuery.data!.cards.length === 0 && (
              <Text>Add credit card details below to avoid interruptions.</Text>
            )}
          </Panel>

          <Panel>
            <Text weight="bold" css={{ marginBottom: "1.5rem" }}>
              Card details
            </Text>

            {subscriptionQuery.data!.cards.length === 0 && (
              <form onSubmit={handleSubmit}>
                <CardInputWrapper>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontFamily: "EudoxusSans",
                        },
                      },
                    }}
                  />
                </CardInputWrapper>

                <Button
                  size="medium"
                  disabled={!stripe || isAddCardMutationLoading}
                  type="submit"
                >
                  {isAddCardMutationLoading ? "Adding card..." : "Add card"}
                </Button>
              </form>
            )}

            {subscriptionQuery.data!.cards.map((card) => (
              <Card key={`${card.last4}-${card.created_at}`}>
                <div>{capitalizeString(card.brand)}</div>
                <div style={{ marginLeft: "0.75rem" }}>••••{card.last4}</div>
                <div style={{ marginLeft: "5.25rem" }}>
                  Expires {numberInTwoDigits(card.exp_month)}/{card.exp_year}
                </div>
              </Card>
            ))}
          </Panel>
        </>
      ) : (
        <>
          <Panel>
            <Text weight="bold" css={{ marginBottom: "1.5rem" }}>
              Subscription
            </Text>

            <Text>You don't have an active Yem subscription.</Text>
          </Panel>
        </>
      )}
    </Wrapper>
  );
};

const ElementsProvider = () => {
  const stripePromise = React.useMemo(() => {
    if (!window.stripejs) {
      window.stripejs = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
    }

    return window.stripejs;
  }, []);

  return (
    <Elements stripe={stripePromise} options={{ fonts: stripeFontFamilies }}>
      <BillingSettings />
    </Elements>
  );
};

export default ElementsProvider;
