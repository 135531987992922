import { CSS } from "@stitches/react/types/css-util";
import React from "react";
import { styled } from "stitches.config";

const Wrapper = styled("div", {
  position: "relative",
  "&:after": {
    content: "",
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "$cyan",
    zIndex: -1,
    borderRadius: "$medium",
    top: "0.5rem",
    left: "0.5rem",
  },
});

const Img = styled("img", {
  width: "100%",
  display: "block",
  borderRadius: "$medium",
});

const ImgWrapper = styled("div", {
  borderRadius: "$medium",
  boxShadow: "$boldBorder",
});

const ShadowPadding = styled("div", {
  paddingTop: "2px",
  paddingLeft: "2px",
  paddingRight: "calc(0.5rem + 2px)",
  paddingBottom: "calc(0.5rem + 2px)",
});

const BoxedImageWrapper = styled("div", {});

const BoxedImage: React.FC<{ src: string; css?: CSS }> = ({ src, css }) => {
  return (
    <BoxedImageWrapper css={css}>
      <ShadowPadding>
        <Wrapper>
          <ImgWrapper>
            <Img src={src} />
          </ImgWrapper>
        </Wrapper>
      </ShadowPadding>
    </BoxedImageWrapper>
  );
};

export default BoxedImage;
