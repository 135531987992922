import { styled } from "stitches.config";

const Input = styled("input", {
  padding: "0.75rem",
  lineHeight: "24px",
  backgroundColor: "white",
  border: "1px solid $midnight-t80",
  boxSizing: "border-box",
  fontSize: "$normal",
  borderRadius: "$small",
  height: "2.5rem",
  width: "100%",
  transitionDuration: "200ms",

  "&:hover": {
    borderColor: "$cyan",
  },

  "&:focus": {
    outline: "none",
    borderColor: "$cyan",
    boxShadow: "0px 0px 0px 4px rgba(110, 176, 178, 0.24)",
  },

  variants: {
    size: {
      large: {
        height: "3rem",
      },
    },
  },
});

export default Input;
