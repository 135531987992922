import React from "react";
import { styled } from "stitches.config";
import { ReactComponent as CaretDownIcon } from "assets/icons/caret-down.svg";

const SelectWrapper = styled("select", {
  fontSize: "16px",
  lineHeight: "24px",
  padding: "0.75rem",
  border: "solid 1px $midnight-t80",
  borderRadius: "$small",
  width: "100%",
  appearance: "none",

  "&:required:invalid": {
    color: "#9B9DA1",
  },

  "&:hover": {
    borderColor: "$cyan",
  },

  "&:focus": {
    outline: "none",
    borderColor: "$cyan",
    boxShadow: "0px 0px 0px 4px rgba(110, 176, 178, 0.24)",
  },
});

const CaretDownIconWrapper = styled("div", {
  position: "absolute",
  width: "1.5rem",
  height: "1.5rem",
  right: "1rem",
  top: "50%",
  transform: "translateY(-50%)",
  userSelect: "none",
  pointerEvents: "none",
});

const Select: React.FC<{
  placeholder?: string;
  options: { value: string; label: string }[];
  onChange: (newVal: string) => void;
  value: string;
}> = ({ placeholder, options, value, onChange }) => {
  return (
    <div style={{ position: "relative" }}>
      <SelectWrapper
        required
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="" disabled selected>
          {placeholder}
        </option>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </SelectWrapper>
      <CaretDownIconWrapper>
        <CaretDownIcon />
      </CaretDownIconWrapper>
    </div>
  );
};

export default Select;
