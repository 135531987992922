import React, { FormEvent } from "react";
import { Editor, Text, Transforms } from "slate";
import { useSlate } from "slate-react";
import Button from "uikit/button";
import { Dialog, DialogButtons, Title } from "uikit/dialog";
import Field from "uikit/field";
import Input from "uikit/input";

const LinkModal: React.FC<{ open: boolean; onOpenChange: () => void }> = ({
  open,
  onOpenChange,
}) => {
  const editor = useSlate();

  const [text, setText] = React.useState("");

  React.useEffect(() => {
    if (open && editor.selection) {
      setText(Editor.string(editor, editor.selection));
    } else if (!open) {
      setText("");
    }
  }, [open, editor]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    Transforms.setNodes(
      editor,
      { href: "https://google.com" },
      { match: (n) => Text.isText(n), split: true }
    );

    onOpenChange();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} width="28rem">
      <Title css={{ marginBottom: "1.5rem" }}>Edit link</Title>

      <form onSubmit={handleSubmit}>
        <Field label="Text to display">
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            autoFocus
            required
            type="text"
          />
        </Field>

        <Field label="To which URL should this link refer?">
          <Input required type="url" />
        </Field>

        <DialogButtons>
          <Button secondary size="medium" type="button" onClick={onOpenChange}>
            Cancel
          </Button>
          <Button size="medium" type="submit" css={{ marginLeft: "1rem" }}>
            Done
          </Button>
        </DialogButtons>
      </form>
    </Dialog>
  );
};

export default LinkModal;
