import * as Progress from "@radix-ui/react-progress";
import { styled } from "stitches.config";

export const ProgressBar = styled(Progress.Root, {
  height: "1rem",
  backgroundColor: "$light-neutral-t10",
  width: "100%",
  borderRadius: "$small",
  overflow: "hidden",
});

export const Indicator = styled(Progress.Indicator, {
  backgroundColor: "$cyan",
  height: "100%",
});
