import React from "react";
import InviteSubstackAccountPage from "pages/onboarding/inviteSubstackAccount";
import { OnboardingContextProvider } from "pages/onboarding/context";
import { Switch, Route, Redirect } from "react-router-dom";
import SubstackAdminAccessGrant from "./substackAdminAccessGrant";
import ConnectStripeAccount from "./connectStripeAccount";
import NewsletterDetails from "./newsletterDetails";
import OnboardingCompleted from "./completed";

const Onboarding = () => {
  return (
    <OnboardingContextProvider>
      <Switch>
        <Route
          exact
          path="/onboarding/link-substack-account"
          component={InviteSubstackAccountPage}
        />
        <Route
          exact
          path="/onboarding/substack-admin-access"
          component={SubstackAdminAccessGrant}
        />
        <Route
          exact
          path="/onboarding/connect-stripe-account"
          component={ConnectStripeAccount}
        />
        <Route
          exact
          path="/onboarding/completed"
          component={OnboardingCompleted}
        />

        <Route exact path="/signup" component={NewsletterDetails} />

        <Redirect to="/onboarding/link-substack-account" />
      </Switch>
    </OnboardingContextProvider>
  );
};

export default Onboarding;
