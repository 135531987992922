import React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { styled } from "stitches.config";

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  width: "1.5rem",
  height: "1.5rem",
  borderRadius: "50%",
  border: "solid 2px $midnight",
  padding: 0,
});

const Label = styled("label", {
  margin: 0,
  marginLeft: "0.5rem",
  marginBottom: "0",
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "$midnight",
  },
});

const Option = styled("div", {
  display: "flex",
  alignItems: "center",
  marginBottom: "1rem",
});

export const RadioOption: React.FC<{ value: string; label: string }> = ({
  value,
  label,
}) => {
  return (
    <Option>
      <StyledRadio value={value}>
        <StyledIndicator />
      </StyledRadio>

      <Label>{label}</Label>
    </Option>
  );
};

export const RadioGroup: React.FC<{
  name: string;
  required?: boolean;
  value: string;
  onChange: (newVal: string) => void;
}> = ({ children, required, onChange, value, name }) => {
  return (
    <RadioGroupPrimitive.Root
      required={required}
      name={name}
      value={value}
      onValueChange={onChange}
    >
      {children}
    </RadioGroupPrimitive.Root>
  );
};

export default RadioGroup;
