import { PageBar } from "components/header";
import { styled } from "stitches.config";
import React from "react";
import Button from "uikit/button";
import Text from "uikit/text";
import { ReactComponent as HamburgerIcon } from "assets/icons/bars.svg";
import YemEditor from "components/editor";
import EmailSelectionDrawer from "./emailSelectionDrawer";
import { useAPIClient } from "lib/api";
import { ILifecycleCampaign } from "lib/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Redirect,
  Switch,
  Route,
  RouteComponentProps,
  useLocation,
  useHistory,
} from "react-router";
import { CustomElement } from "components/editor/types";
import Toolbar from "uikit/toolbar";
import lifecycleCampaignsHelp, {
  lifecycleCampaignsNames,
} from "./lifecycleCampaignsHelp";
import OnboardingCompletedDialog from "./onboardingCompletedDialog";
import AllEmailSetupDialog from "./allEmailsSetupDialog";

const PageWrapper = styled("div", {
  flex: 1,
  backgroundColor: "#E7E9EC",
  display: "flex",
  flexDirection: "column",
  minHeight: 0,
});

const EditorWrapper = styled("div", {
  padding: "2.5rem",
  flex: 1,
  display: "flex",
});

const Buttons = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Flex = styled("div", {
  display: "flex",
  flex: 1,
  minHeight: 0,
});

const EmailHelpSection = styled("div", {
  backgroundColor: "$white",
  width: "25rem",
  padding: "1rem 1.5rem",
  overflow: "auto",
});

const SelectedEmailWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const HamburgerIconWrapper = styled("div", {
  cursor: "pointer",
  display: "flex",
});

const CustomizePage: React.FC<
  RouteComponentProps<{ lifecycleCampaignId: string }> & {
    lifecycleCampaigns: ILifecycleCampaign[];
    onboardingDialogOpen: boolean;
    onOnboardingDialogClose: () => void;
  }
> = ({
  match,
  lifecycleCampaigns,
  onboardingDialogOpen,
  onOnboardingDialogClose,
}) => {
  const apiClient = useAPIClient();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [allEmailsSetupDialogOpen, setAllEmailsSetupDialogOpen] =
    React.useState(false);
  const [emailSelectionDrawerOpen, setEmailSelectionDrawerOpen] =
    React.useState(false);

  const lifecycleCampaign = lifecycleCampaigns.find(
    (campaign) => campaign.id === parseInt(match.params.lifecycleCampaignId, 10)
  )!;

  const isSettingUpEmailsForTheFirstTime = React.useMemo(() => {
    const foundCampaign = lifecycleCampaigns.find(
      (campaign) => !campaign.active
    );

    return Boolean(foundCampaign);
  }, [lifecycleCampaigns]);

  const [editorValue, setEditorValue] = React.useState<CustomElement[]>(
    () =>
      lifecycleCampaign.template?.raw || [
        { type: "paragraph", children: [{ text: "Write email here..." }] },
      ]
  );
  const [subject, setSubject] = React.useState(
    lifecycleCampaign.template?.subject || ""
  );
  const [editorDefaultValue, setEditorDefaultValue] =
    React.useState(editorValue);

  React.useEffect(() => {
    const newVal = lifecycleCampaign.template?.raw || [
      { type: "paragraph", children: [{ text: "Write email here..." }] },
    ];
    setEditorDefaultValue(newVal);
    setSubject(lifecycleCampaign.template?.subject || "");
  }, [lifecycleCampaign]);

  const updateLifecycleCampaign = React.useCallback(
    async ({
      rawTemplate,
      subject,
    }: {
      subject: string;
      rawTemplate: CustomElement[];
    }) => {
      return apiClient.patch<ILifecycleCampaign>(
        `/lifecycle-campaigns/${lifecycleCampaign.id}`,
        {
          active: true,
          template: {
            raw: rawTemplate,
            subject,
          },
        }
      );
    },
    [apiClient, lifecycleCampaign.id]
  );

  const updateLifecycleCampaignMutation = useMutation(updateLifecycleCampaign);

  const handleSave = React.useCallback(() => {
    return updateLifecycleCampaignMutation.mutateAsync(
      { rawTemplate: editorValue, subject: subject },
      {
        onSuccess: (result) => {
          queryClient.setQueryData("lifecycleCampaigns", (oldData) => {
            const existingLifecycleCampaigns = oldData as ILifecycleCampaign[];
            return existingLifecycleCampaigns.map((lifecycleCampaign) => {
              if (lifecycleCampaign.id === result.data.id) {
                return result.data;
              }

              return lifecycleCampaign;
            });
          });
          const nextCampaign = lifecycleCampaigns.find((campaign, i) => {
            return !campaign.active && campaign.id !== lifecycleCampaign.id;
          });

          if (isSettingUpEmailsForTheFirstTime) {
            if (nextCampaign) {
              history.push(`/customize/${nextCampaign.id}`);
            } else {
              setAllEmailsSetupDialogOpen(true);
            }
          }
        },
      }
    );
  }, [
    editorValue,
    updateLifecycleCampaignMutation,
    queryClient,
    subject,
    lifecycleCampaigns,
    history,
    lifecycleCampaign,
    isSettingUpEmailsForTheFirstTime,
  ]);

  const unsavedChanges = React.useMemo(() => {
    const apiValue = lifecycleCampaign.template?.raw || [
      { type: "paragraph", children: [{ text: "Write email here..." }] },
    ];

    return JSON.stringify(editorValue) !== JSON.stringify(apiValue);
  }, [editorValue, lifecycleCampaign]);

  return (
    <PageWrapper>
      <OnboardingCompletedDialog
        open={onboardingDialogOpen}
        onClose={onOnboardingDialogClose}
      />
      <AllEmailSetupDialog
        open={allEmailsSetupDialogOpen}
        onClose={() => setAllEmailsSetupDialogOpen(false)}
      />
      <PageBar.Source>
        <EmailSelectionDrawer
          open={emailSelectionDrawerOpen}
          onExit={() => setEmailSelectionDrawerOpen(false)}
          lifecycleCampaigns={lifecycleCampaigns}
          selectedCampaignId={match.params.lifecycleCampaignId}
          hasUnsavedChanges={unsavedChanges}
          isSaving={updateLifecycleCampaignMutation.isLoading}
          isSettingUpEmailsForTheFirstTime={isSettingUpEmailsForTheFirstTime}
          onSave={handleSave}
        />
        <Toolbar>
          <SelectedEmailWrapper>
            <HamburgerIconWrapper
              onClick={() => setEmailSelectionDrawerOpen(true)}
            >
              <HamburgerIcon />
            </HamburgerIconWrapper>
            <Text weight="bold" size="small" css={{ marginLeft: "0.5rem" }}>
              {lifecycleCampaignsNames[lifecycleCampaign.name] ||
                lifecycleCampaign.name}
            </Text>
          </SelectedEmailWrapper>

          <Buttons>
            <Button
              size="medium"
              onClick={handleSave}
              disabled={updateLifecycleCampaignMutation.isLoading}
            >
              {updateLifecycleCampaignMutation.isLoading
                ? "Saving..."
                : isSettingUpEmailsForTheFirstTime
                ? "Save & Next"
                : "Save"}
            </Button>
          </Buttons>
        </Toolbar>
      </PageBar.Source>

      <Flex>
        <EditorWrapper>
          <YemEditor
            value={editorValue}
            onChange={(newVal) => {
              setEditorValue(newVal as CustomElement[]);
            }}
            subject={subject}
            onSubjectChange={setSubject}
            defaultValue={editorDefaultValue}
          />
        </EditorWrapper>

        <EmailHelpSection>
          {lifecycleCampaignsHelp[lifecycleCampaign.name] &&
            React.createElement(lifecycleCampaignsHelp[lifecycleCampaign.name])}
        </EmailHelpSection>
      </Flex>
    </PageWrapper>
  );
};

const CustomizeRouting: React.FC<RouteComponentProps> = ({ match }) => {
  const apiClient = useAPIClient();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const [onboardingDialogOpen, setOnboardingDialogOpen] = React.useState(
    Boolean(queryString.get("from_onboarding"))
  );

  const fetchLifecycleCampaigns = React.useCallback(async () => {
    const response = await apiClient.get<ILifecycleCampaign[]>(
      "/lifecycle-campaigns"
    );
    return response.data;
  }, [apiClient]);

  const lifecycleCampaignsQuery = useQuery(
    "lifecycleCampaigns",
    fetchLifecycleCampaigns
  );

  if (!lifecycleCampaignsQuery.isFetched) {
    return null;
  }

  return (
    <Switch>
      <Route
        path={`${match.path}/:lifecycleCampaignId`}
        render={(props) => (
          <CustomizePage
            {...props}
            onboardingDialogOpen={onboardingDialogOpen}
            onOnboardingDialogClose={() => setOnboardingDialogOpen(false)}
            lifecycleCampaigns={lifecycleCampaignsQuery.data!}
          />
        )}
      />
      {lifecycleCampaignsQuery.data!.length > 0 && (
        <Redirect to={`${match.path}/${lifecycleCampaignsQuery.data![0].id}`} />
      )}
    </Switch>
  );
};

export default CustomizeRouting;
