import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { styled } from "stitches.config";

export const DropdownMenu = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger = styled(DropdownMenuPrimitive.Trigger, {
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",

  "&:focus": {
    outline: "none",
  },
});

export const DropdownMenuContent = styled(
  DropdownMenuPrimitive.DropdownMenuContent,
  {
    backgroundColor: "white",
    padding: "0.5rem",
    borderRadius: 4,
    boxShadow:
      "0px 0px 0px 1px rgba(34, 76, 129, 0.12), 0px 2px 4px rgba(34, 76, 129, 0.1)",
  }
);

export const DropdownMenuItem = styled(DropdownMenuPrimitive.DropdownMenuItem, {
  padding: 4,
  display: "flex",
  alignItems: "center",
  marginBottom: "0.25rem",
  cursor: "pointer",
  borderRadius: "$tiny",
  transitionDuration: "200ms",
  fontSize: 14,
  lineHeight: "24px",

  "&:last-child": {
    marginBottom: "0rem",
  },

  "&:hover": {
    outline: "none",
    backgroundColor: "$light-neutral-t30",
  },

  svg: {
    marginRight: "0.5rem",
  },

  variants: {
    bold: {
      true: {
        fontWeight: "$bold",
      },
    },
    active: {
      true: {
        backgroundColor: "$light-neutral",

        "&:hover": {
          backgroundColor: "$light-neutral",
        },
      },
    },
  },
});
