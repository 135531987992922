import React from "react";
import { Dialog } from "uikit/dialog";
import Text from "uikit/text";
import animationData from "assets/lotties/done-lottie.json";
import Lottie from "react-lottie";
import Button from "uikit/button";

const OnboardingCompletedDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onOpenChange={onClose} width="25rem" blurredBackground>
      <Lottie
        height={160}
        width={160}
        options={{ animationData, loop: false }}
      />
      <Text
        size="heading6"
        weight="bold"
        align="center"
        css={{ marginTop: "1rem", marginBottom: "0.5rem" }}
      >
        Setup complete!
      </Text>
      <Text secondary align="center">
        We've crafted a set of emails to go out at specific moments in a
        subscriber's lifecycle to boost engagement, convert free subs to paid
        and retain paid subs. Please review and adjust the copy to fit your
        brand/voice.
      </Text>

      <Button
        block
        css={{ marginTop: "2.5rem" }}
        type="button"
        onClick={onClose}
      >
        Start reviewing
      </Button>
    </Dialog>
  );
};

export default OnboardingCompletedDialog;
