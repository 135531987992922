import React, { FormEvent } from "react";
import { styled } from "stitches.config";
import { ReactComponent as YemLogo } from "assets/vectors/yem-logo.svg";
import Text from "uikit/text";
import Field from "uikit/field";
import Button from "uikit/button";
import Select from "uikit/select";
import RadioGroup, { RadioOption } from "uikit/radio";
import { useAPIClient } from "lib/api";
import { Redirect } from "react-router";
import { useAuth } from "lib/auth";
import { useMutation } from "react-query";

const Wrapper = styled("div", {
  width: "36rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "3rem",
  boxSizing: "border-box",
});

const LogoWrapper = styled("div", {
  height: "4.5rem",
  marginBottom: "0.75rem",
});

const Panel = styled("div", {
  backgroundColor: "$cereal-milk-s20",
  padding: "4rem 5.5rem",
  borderRadius: "$large",
  width: "100%",
  boxSizing: "border-box",
});

const Fields = styled("div", {
  marginBottom: "2rem",
});

const NewsletterDetails = () => {
  const [hasPaidVersion, setHasPaidVersion] = React.useState("");
  const [newsletterProvider, setNewsletterProvider] = React.useState("");
  const [finished, setFinished] = React.useState(false);
  const apiClient = useAPIClient();
  const auth = useAuth();

  const submitNewsletterDetails = React.useCallback(async () => {
    await apiClient.post("/submit-newsletter-details", {
      newsletter_provider: newsletterProvider,
      has_paid_version: hasPaidVersion,
    });

    setFinished(true);
  }, [newsletterProvider, hasPaidVersion, apiClient]);

  const submitNewsletterDetailsMutation = useMutation(submitNewsletterDetails);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    window.analytics.track("NewsletterDetailsForm Submitted");
    submitNewsletterDetailsMutation.mutate();
  };

  React.useEffect(() => {
    window.analytics.track("NewsletterDetailsForm Loaded");
  }, []);

  if (finished && newsletterProvider === "substack") {
    return <Redirect to="/onboarding/link-substack-account" />;
  } else if (finished) {
    auth.logout();
    return <Redirect to="/signup/finished" />;
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <YemLogo width="94px" height="4.5rem" viewBox="0 0 73 56" />
      </LogoWrapper>
      <Panel>
        <form onSubmit={handleSubmit}>
          <Text
            align="center"
            weight="bold"
            size="heading5"
            css={{ marginBottom: "2rem" }}
          >
            Tell us about your newsletter
          </Text>

          <Fields>
            <Field label="Newsletter Provider" strong>
              <Select
                placeholder="Select your newsletter provider"
                value={newsletterProvider}
                onChange={setNewsletterProvider}
                options={[
                  {
                    value: "substack",
                    label: "Substack",
                  },
                  {
                    value: "convertkit",
                    label: "ConvertKit",
                  },

                  {
                    value: "mailchimp",
                    label: "Mailchimp",
                  },

                  {
                    value: "ghost",
                    label: "Ghost",
                  },

                  {
                    value: "beehiiv",
                    label: "Beehiiv",
                  },

                  {
                    value: "convertkit",
                    label: "ConvertKit",
                  },

                  {
                    value: "revue",
                    label: "Revue",
                  },

                  {
                    value: "medium",
                    label: "Medium",
                  },

                  {
                    value: "other",
                    label: "Other",
                  },
                ]}
              />
            </Field>

            <Field
              label="Do you offer a paid version of your newsletter?"
              strong
            >
              <RadioGroup
                required
                name="has_paid_version"
                value={hasPaidVersion}
                onChange={setHasPaidVersion}
              >
                <RadioOption value="yes" label="Yes" />
                <RadioOption value="no" label="No" />
              </RadioGroup>
            </Field>
          </Fields>

          <Button
            type="submit"
            block
            disabled={submitNewsletterDetailsMutation.isLoading}
          >
            {submitNewsletterDetailsMutation.isLoading
              ? "Submitting..."
              : "Continue"}
          </Button>
        </form>
      </Panel>
    </Wrapper>
  );
};

export default NewsletterDetails;
