import React from "react";
import { Path } from "slate";

const EditorContext = React.createContext<{
  buttonToEdit: Path | null;
  setButtonToEdit: (val: Path | null) => void;
}>({ buttonToEdit: null, setButtonToEdit: () => null });

export const EditorContextProvider: React.FC = ({ children }) => {
  const [buttonToEdit, setButtonToEdit] = React.useState<Path | null>(null);
  const val = React.useMemo(() => {
    return {
      buttonToEdit,
      setButtonToEdit,
    };
  }, [buttonToEdit]);
  return (
    <EditorContext.Provider value={val}>{children}</EditorContext.Provider>
  );
};

export const useEditorContext = () => React.useContext(EditorContext);
