import { createStitches } from "@stitches/react";

export const { styled, css, globalCss } = createStitches({
  theme: {
    colors: {
      white: "#FFFFFF",
      midnight: "#040B15",
      "midnight-t10": "#1D232C",
      "midnight-t20": "#363C44",
      "midnight-t50": "#898E96",
      "midnight-t80": "#CDCED0",
      "cereal-milk": "#F9F9F9",
      "cereal-milk-s20": "#F1EBDE",
      "late-night-t60": "#CAD4E1",
      "late-night-t80": "#E4EAF0",
      cyan: "#6EB0B2",
      "cyan-s10": "#639EA0",
      tan: "#DBAB63",
      "tan-t30": "#EFE0C9",
      "light-neutral": "#D9DCE1",
      "light-neutral-t10": "#E7E9EC",
      "light-neutral-t20": "#EFF1F3",
      "light-neutral-t30": "#F5F7F8",
      "light-neutral-t40": "#F9FAFB",
      danger: "#EB5757",
      "danger-t90": "#FFF2EF",
      "danger-s20": "#BD3F2C",
      "success-s20": "#588E5A",
      success: "",
      "success-t80": "#E2F0E2",
    },
    shadows: {
      boldBorder: "0px 0px 0px 2px #040B15",
    },
    radii: {
      large: "32px",
      medium: "16px",
      small: "8px",
      tiny: "4px",
    },
    fontWeights: {
      medium: "600",
      bold: "700",
      extraBold: "800",
    },
    fontSizes: {
      heading4: "32px",
      heading5: "24px",
      heading6: "20px",
      medium: "18px",
      normal: "16px",
      small: "14px",
      tiny: "12px",
    },
  },
});
