import React from "react";
import { styled } from "stitches.config";

const Wrapper = styled("div", {
  padding: "1rem 0",
  margin: "0rem 1.5rem",
  borderBottom: "solid 1px $late-night-t80",
  display: "flex",
  fontSize: "16px",
  alignItems: "center",
  lineHeight: "24px",

  label: {
    color: "$midnight-t50",
  },

  input: {
    marginLeft: "2rem",
    border: "none",
    fontSize: "16px",
    lineHeight: "24px",
    flex: 1,

    "&:focus": {
      outline: "none",
    },
  },
});

const SubjectEditor: React.FC<{
  onChange: (newVal: string) => void;
  value: string;
}> = ({ value, onChange }) => {
  return (
    <Wrapper>
      <label>Subject:</label>

      <input value={value} onChange={(e) => onChange(e.target.value)} />
    </Wrapper>
  );
};

export default SubjectEditor;
