import React from "react";
import { styled } from "stitches.config";
import Input from "uikit/input";

const FieldWrapper = styled("div", {
  marginBottom: "1.5rem",
  "label.fieldLabel": {
    marginBottom: "0.5rem",
    display: "block",
  },

  "&:last-child": {
    marginBottom: "0rem",
  },

  variants: {
    horizontal: {
      true: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    strong: {
      true: {
        fontWeight: "$bold",
      },
    },
    hasError: {
      true: {
        [Input.toString()]: {
          border: "1px solid $danger",
          "&:focus": {
            borderColor: "$danger",
            boxShadow: "none",
          },
        },
      },
    },
  },
});

const ErrorMessage = styled("span", {
  fontSize: 14,
  lineHeight: "24px",
  marginTop: "0.25rem",
  color: "$danger",
  fontWeight: "$medium",
});

const Field: React.FC<{
  label: string;
  strong?: boolean;
  horizontal?: boolean;
  error?: string;
}> = ({ label, children, strong, horizontal, error }) => {
  return (
    <FieldWrapper
      strong={strong}
      horizontal={horizontal}
      hasError={Boolean(error)}
    >
      <label className="fieldLabel">{label}</label>
      {children}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FieldWrapper>
  );
};

export default Field;
